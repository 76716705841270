import React from 'react';
import { observer } from "mobx-react"
import {toJS} from 'mobx';
import ewtStore from '../EWTStore';
import {Modal,Button} from 'antd';
import {PlusSquareFilled,MinusSquareFilled} from '@ant-design/icons';


const CorrectScore = observer(class CorrectScore extends React.Component {
	constructor(props) {
		super(props);
		this.state = {newScore:!ewtStore.correctScore.score?4:ewtStore.correctScore.score}
		this.updateScore = this.updateScore.bind(this);
		this.deleteScore = this.deleteScore.bind(this);
	}

	deleteScore() {
		ewtStore.deleteScore(ewtStore.correctScore);
		ewtStore.correctScore = null;
	}
	updateScore() {
		var hole = ewtStore.correctScore;
		var score = this.state.newScore;
		ewtStore.setScore(hole.roundID,hole.playerID,hole.num,score);
		ewtStore.correctScore = null;
	}


	render() {
		var hole = ewtStore.correctScore;
		console.dir(toJS(hole));
		return (
			<Modal
				title={<div className='headerText' style={{width:'100%',padding:0,margin:0,height:30}}>
						<div className="headerText">
							Correct Score
						</div>
					</div>}
					visible={true}
				onCancel={() => ewtStore.correctScore = null}
				bodyStyle={{margin:0,padding:0,height:ewtStore.windowHeight - 130,backgroundColor:'#E8E8E8'}}
				style={{top:0}}
				closable={true}
				footer={null}					
				>
				<div className="flexColumn flexGrow" style={{fontSize:26,height:window.innerHeight - 130,padding:5}}>
					<div className="flexRow">Player&nbsp;<b>{ewtStore.players[hole.playerID].first_name}&nbsp;{ewtStore.players[hole.playerID].last_name}</b><br></br></div>
					<div className="flexRow">Round&nbsp;<b>{ewtStore.rounds[hole.roundID].desc}</b></div>
					<div className="flexRow">Hole&nbsp;<b>{hole.num}</b></div>
					<div className="flexRow">Current Score&nbsp;<b>{hole.score}</b></div>
					<div className="flexRow">
						<div style={{marginTop:12}}>New Score&nbsp;</div>
						<div style={{width:40,fontSize:38,padding:5,cursor:'pointer',color:ewtStore.color3}} onClick={() => this.setState({newScore:this.state.newScore===1?1:this.state.newScore-1})}><MinusSquareFilled></MinusSquareFilled></div>
						<div style={{marginTop:12}}>&nbsp;&nbsp;<b>{this.state.newScore}</b>&nbsp;</div>
						<div style={{width:40,fontSize:38,padding:5,cursor:'pointer',color:ewtStore.color3}} onClick={() => this.setState({newScore:this.state.newScore+1})}><PlusSquareFilled></PlusSquareFilled></div>
					</div>
					<div className="flexRow" style={{marginTop:20}}>
						<Button onClick={() => this.updateScore()} type="primary" style={{width:150}}>Make Change</Button>
						<Button onClick={() => ewtStore.correctScore = null} type="primary" style={{width:100,marginLeft:20}}>Cancel</Button>
						<Button onClick={() => this.deleteScore()} type="primary" style={{width:100,marginLeft:10}}>Delete Score</Button>
					</div>
				</div>
			</Modal>
	  	);
	}
});


export default CorrectScore;