import React from 'react';
import { observer } from "mobx-react"
import ewtStore from '../EWTStore';
import Pairings from './Pairings';
import {Modal,Button} from 'antd';
import {reaction} from 'mobx';
import {PlusSquareFilled,MinusSquareFilled,RightSquareFilled,LeftSquareFilled,ExclamationCircleOutlined} from '@ant-design/icons';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import RoundSelector from './RoundSelector';
import CourseCard from './CourseCard';

const { confirm } = Modal;

const Scoring = observer(class Scoring extends React.Component {

	constructor(props) {
		super(props);
		this.changeScore = this.changeScore.bind(this);
		this.changeHole = this.changeHole.bind(this);
		this.closeRound = this.closeRound.bind(this);
		this.roundChanged = this.roundChanged.bind(this);
		const reaction1 = reaction(
			() => ewtStore.currentRoundID,
			(currentRoundID) => this.setState({roundID:currentRoundID,course:ewtStore.courses[ewtStore.rounds[currentRoundID].courseID]})
		);
		this.state = {currentHole:1,currentHoleScores:{},showSelectHole:false,endRound:false,roundID:ewtStore.currentRoundID,holeMapURL:null,currentRoundReaction:reaction1,course:ewtStore.courses[ewtStore.rounds[ewtStore.currentRoundID].courseID]}
	}

	componentDidMount() {
		this.setState({currentHole:this.calcMaxHoleScored(this.state.roundID)});
	}

	comopentWillUmount() {
		if (this.state.currentRoundReaction) this.state.currentRoundReaction.dispose();
	}

	closeRound() {
		this.setState({endRound:false})
	}

	changeHole(increment,hole) {
		this.setState({showSelectHole:false});
		if (hole) {
			increment = hole - this.state.currentHole;
		}
		if (increment === -1 && this.state.currentHole === 1) return;
		if (increment > 0 && (this.state.currentHole >= this.calcMaxHoleScored(this.state.roundID))) {
			var roundID = this.state.roundID;
			var missingScores = false;
			if (!ewtStore.roundScores[roundID]) {
				missingScores = true;
			}
			if (!missingScores) {
				ewtStore.groupPlayers.forEach((player) => {
					if (!ewtStore.roundScores[roundID][player.id]) missingScores = true;
					if (!missingScores) missingScores = (!ewtStore.roundScores[roundID][player.id][this.state.currentHole]);
				});
			}
			missingScores = false; //allow hole advancement?
			if (missingScores) {
				confirm({
					title: 'Missing Score(s)',
					icon: <ExclamationCircleOutlined />,
					content: 'You must record a score for all players in the group before advancing to the next hole.  If you wish to look ahead to future holes, tap the course link at the top.',
					okText:"OK",
					cancelText:null,
					cancelButtonProps:{ style: { display: 'none' } },
					onOk() {
					},
				});
			} else {
				if (this.state.currentHole === 18 && increment > 0) {
					this.setState({endRound:true})
				} else {
					this.setState({currentHole:this.state.currentHole + increment});
				}
			}
			//check if everyone's score is par, and then confirm - what about when jumping ahead a bunch of holes? or disallow in the menu?
			if (false) {
				var par = this.state.course.holes[this.state.currentHole].tees[ewtStore.rounds[this.state.roundID].tees].par;
				var foundNonPar = false;
				var that = this;
				ewtStore.groupPlayers.forEach((player) => {
					if (that.state.currentHoleScores[player.id] !== par) foundNonPar = true;
				})
				if (!foundNonPar) {
					confirm({
						title: 'Did all players score a par on this hole?',
						icon: <ExclamationCircleOutlined />,
						content: 'Seems highly unlikely, to look ahead at future holes - tap the course name at the top',
						okText:"Yes - they really did",
						cancelText:"Fix It",
						onOk() {
							//this only scores one player's score
							that.changeScore(ewtStore.groupPlayers[0].id,0); //since we are advancing to the next hole with all pars - need to store the scores
						},
						onCancel() {},
					});
				} else {
					if (this.state.currentHole === 18) {
						this.setState({endRound:true})
					} else {
						this.setState({currentHole:this.state.currentHole + increment});
					}
				}
			}
		} else {
			if (this.state.currentHole === 18 && increment > 0) {
				this.setState({endRound:true})
			} else {
				this.setState({currentHole:this.state.currentHole + increment});
			}
		}
	}

	changeScore(playerID,increment) {
		var roundID = this.state.roundID;
		if (ewtStore.roundScores[roundID] && ewtStore.roundScores[roundID][playerID] && ewtStore.roundScores[roundID][playerID][this.state.currentHole]) {
			var score = ewtStore.roundScores[roundID][playerID][this.state.currentHole];
			if (increment === -1 && score.score === 1) return;
			score.score += increment;
			ewtStore.setScore(roundID,playerID,this.state.currentHole,score.score);
			// score = ewtStore.roundScores[ewtStore.roundID][playerID][this.state.currentHole];
			// score.score += increment;
			// if (score.score < 1) score.score = 1;
			// ewtStore.firebaseDB.collection("scores").doc(score.id).update({
			// 	score:score.score
			// });
		} else { //add new record
			var par = this.state.course.holes[this.state.currentHole].tees[ewtStore.rounds[roundID].tees].par;
			ewtStore.setScore(roundID,playerID,this.state.currentHole,par);
			// score = {roundID:roundID,playerID:playerID,hole:this.state.currentHole,tournamentID:ewtStore.tournament.id,score:par}
			// ewtStore.firebaseDB.collection("scores").add(score);
		}
		// if (increment === 0) {
		// 	this.setState({currentHole:this.state.currentHole+1})
		// }
		return;


		// if (increment === -1 && this.state.currentHoleScores[playerID] === 1) return;
		// var currentHoleScores = this.state.currentHoleScores;
		// currentHoleScores[playerID] += increment;
		// this.setState({currentHoleScores:currentHoleScores});
		// var playerIDs = [];
		// ewtStore.groupPlayers.forEach((player) => {
		// 	playerIDs.push(player.id);
		// })
		// //try and get the scores for this hole, update the ones found, otherwise create them
		// ewtStore.firebaseDB.collection("scores").where("roundID","==",ewtStore.rounds[ewtStore.currentRoundID].id).where("hole","==",this.state.currentHole).where("playerID","in",playerIDs).where("tournamentID","==",ewtStore.tournament.id).get()
		// .then(function(querySnapshot) {
		// 	var batch = ewtStore.firebaseDB.batch();
		// 	var foundScores = {};
		// 	querySnapshot.forEach(function(doc) {
		// 		var rec = doc.data();
		// 		rec.id = doc.id;
		// 		if (rec.score !== currentHoleScores[rec.playerID]) {
		// 			rec.score = currentHoleScores[rec.playerID];
		// 			var ref = ewtStore.firebaseDB.collection("scores").doc(rec.id);
		// 			batch.set(ref,rec);
		// 		}
		// 		foundScores[rec.playerID] = true;
		// 	})
		// 	playerIDs.forEach((playerID) => {
		// 		if (!foundScores[playerID]) { //need to create one
		// 			var rec = {roundID:ewtStore.rounds[ewtStore.currentRoundID].id,tournamentID:ewtStore.tournament.id,playerID:playerID,hole:this.state.currentHole,score:currentHoleScores[playerID]}
		// 			var ref = ewtStore.firebaseDB.collection("scores").doc();
		// 			batch.set(ref,rec);
		// 		}
		// 	})
		// 	batch.commit();
		// 	if (increment === 0) ewtStore.setCurrentHole(this.state.currentHole + 1,true);
		// });
		
	}
	roundChanged(roundID) {
		this.setState({roundID:roundID,currentHole:this.calcMaxHoleScored(roundID)});
	}

	calcMaxHoleScored(roundID) {
		if (ewtStore.fanMode) return 1;
		if (!ewtStore.groupPlayers) return 1;
		if (!ewtStore.roundScores[roundID]) {
			return 1;
		}
		var maxHole = 1;
		for (var i=0;i<18;i++) {
			var hole = i + 1;
			for (var g=0;g<ewtStore.groupPlayers.length;g++) {
				var player = ewtStore.groupPlayers[g];
				if (!player) continue;
				if (!ewtStore.roundScores[roundID]) return maxHole;
				if (!ewtStore.roundScores[roundID][player.id]) return maxHole;
				if (!ewtStore.roundScores[roundID][player.id][hole]) {
					return maxHole;
				}
			}
			maxHole = hole;
		}
		return 18;
	}

	render() {

		if (!ewtStore.initialized) {
			return <div></div>
		}

		var showHoles = [];
		if (this.state.course) {
			var maxHoleScored = this.calcMaxHoleScored(this.state.roundID);
			showHoles = Object.keys(this.state.course.holes).filter((key) => (maxHoleScored > (key - 2)));
			showHoles = showHoles.sort(function(a,b) {return a - b});
		}
		var round = ewtStore.rounds[this.state.roundID];

		return (
			<div className="flexRow flexGrow"  style={{height:ewtStore.panelHeight,justifyContent:'center',width:'100%'}}>
				<div className="flexColumn noselect" style={{marginLeft:5,marginRight:5,width:'100%'}}>
					{false && <RoundSelector roundID={this.state.roundID} handleEvent={this.roundChanged}></RoundSelector>}
					{!round.open && <Pairings></Pairings>}
					{round.open && round.finalized && <CourseCard roundID={this.state.roundID}></CourseCard>}
					{round.open &&  !round.finalized && <div className="flexColumn flexGrow" style={{maxWidth:400,border:'1px solid ' + ewtStore.color3,padding:5,borderRadius:5}}>
					<div className="flexRow" style={{verticalAlign:'middle',width:'100%',fontSize:34,padding:5,cursor:'pointer',borderRadius:10,height:60,backgroundColor:'#E8E8E8'}}>
								<div style={{width:50,fontSize:36,padding:5,marginTop:-5,cursor:'pointer',color:ewtStore.color3}} onClick={() => this.changeHole(-1)}>
									<LeftSquareFilled></LeftSquareFilled>
								</div>
								<div className="flexGrow" style={{textAlign:'center'}}>
									<span onClick={() => this.setState({showSelectHole:true})}>Hole&nbsp;{this.state.currentHole}&nbsp;</span>
									{true && <img src={ewtStore.mapIconURL} alt="" style={{marginTop:-5,marginLeft:5,height:30}} onClick={() => ewtStore.showHoleMap(this.state.currentHole)}></img>}
								</div>
								<div style={{width:50,fontSize:36,padding:5,marginTop:-5,cursor:'pointer',color:ewtStore.color3}} onClick={() => this.changeHole(1)}>
									<RightSquareFilled></RightSquareFilled>
								</div>
							</div>

						<div className="flexColumn" style={{padding:5,marginTop:-10,height:160}}>
							{ewtStore.groupPlayers && ewtStore.groupPlayers.map((player,index) => {
								var numStrokes = Math.abs(ewtStore.getNet(player.id,this.state.course,round,this.state.currentHole,this.state.course.holes[this.state.currentHole].tees[round.tees].par));
								return (
								<div key={index} className="flexRow" style={{height:60}}>
									<div className="flexColumn" style={{width:150,fontSize:26,marginTop:15,cursor:'pointer'}} onClick={() => {player.showRound = round;ewtStore.showPlayerCard = player}}>
										{ewtStore.players[player.id].shortName}
										<div style={{marginTop:-30,marginLeft:3,height:10}} className="flexRow">
											{numStrokes > 0 && <span><u>&nbsp;&nbsp;</u>&nbsp;&nbsp;</span>}
											{numStrokes > 1 && <span><u>&nbsp;&nbsp;</u>&nbsp;&nbsp;</span>}
											{numStrokes > 2 && <span><u>&nbsp;&nbsp;</u></span>}
										</div>
									</div>
									<div style={{width:40,fontSize:38,padding:5,cursor:'pointer',color:ewtStore.color3}} onClick={() => this.changeScore(player.id,-1)}><MinusSquareFilled></MinusSquareFilled></div>
									<div style={{width:45,fontSize:34,textAlign:'center',marginTop:5}}>
										{ewtStore.roundScores[this.state.roundID] && ewtStore.roundScores[this.state.roundID][player.id] && ewtStore.roundScores[this.state.roundID][player.id][this.state.currentHole] && ewtStore.roundScores[this.state.roundID][player.id][this.state.currentHole].score}
									</div>
									<div style={{width:40,fontSize:38,padding:5,cursor:'pointer',color:ewtStore.color3}} onClick={() => this.changeScore(player.id,1)}><PlusSquareFilled></PlusSquareFilled></div>
								</div>
								)
								})}
						</div>
						<div className="flexRow" style={{height:45,backgroundColor:'#E8E8E8',fontSize:26,padding:5,color:'gray',marginBottom:0,borderRadius:5}}>
							<div>Par {this.state.course.holes[this.state.currentHole].tees[round.tees].par}</div>
							<div className="flexGrow" style={{textAlign:'center'}}>{this.state.course.holes[this.state.currentHole].tees[round.tees].yardage}&nbsp;yards</div>
							<div>hcp&nbsp;#{this.state.course.holes[this.state.currentHole].tees[round.tees].handicap}</div>
						</div>


					</div>}
				</div>


				{this.state.showSelectHole && <Modal
					title={"Select Hole"}
					visible={this.state.showSelectHole}
					bodyStyle={{margin:0,padding:0,height:window.innerHeight - 115}}
					style={{top:0}}
					closable={false}
					footer={[
            			<Button key="back" onClick={() => this.setState({showSelectHole:false})}>
              				Close
			            </Button>
			          ]}					
					>
					<div className="flexColumn flexGrow" style={{height:window.innerHeight - 120}}>
						<StickyTable border={1} style={{fontSize:19,marginTop:-10}}>
					  		<Row>
								<Cell>Hole</Cell>
								<Cell>Par</Cell>
								<Cell>Yards</Cell>
							</Row>
							{showHoles.map((key,index) => {
								return (
								<Row onClick={() => this.changeHole(0,key)} style={{cursor:'pointer'}} key={index}>
									<Cell>{key}</Cell>
									<Cell>{this.state.course.holes[key].tees[round.tees].par}</Cell>
									<Cell>{this.state.course.holes[key].tees[round.tees].yardage}</Cell>
								</Row>
								)
							})}
						</StickyTable>
					</div>
				</Modal>}
				<Modal
					title={'Confirm Round Complete'}
					visible={this.state.endRound}
					onCancel={() => this.setState({endRound:false})}
					onOk={()=> this.closeRound()}
					bodyStyle={{margin:0,padding:0,height:window.innerHeight - 115}}
					style={{top:0}}
					closable={false}
					>
				</Modal>
		</div>
	  );
	}
});


export default Scoring;