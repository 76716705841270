import React from "react";
import { observer } from "mobx-react";
import ewtStore from "../EWTStore";
import { StickyTable, Row, Cell } from "react-sticky-table";
import RoundSelector from "./RoundSelector";
import { Modal, Button } from "antd";

const PlayerCard = observer(
  class PlayerCard extends React.Component {
    constructor(props) {
      super(props);
      if (!ewtStore.showPlayerCard.showRound)
        ewtStore.showPlayerCard.showRound =
          ewtStore.rounds[ewtStore.currentRoundID];
      this.state = {
        roundID: ewtStore.showPlayerCard.showRound.id,
        fullPP: false,
        ppURL: null,
      };
      this.roundChanged = this.roundChanged.bind(this);
    }

    // componentDidMount() {
    //   ewtStore.firebaseStorage
    //     .ref("images/playerpics/t" + ewtStore.tournament.id)
    //     .child("pp" + ewtStore.showPlayerCard.id + ".png")
    //     .getDownloadURL()
    //     .then((url) => {
    //       this.setState({ ppURL: url });
    //     })
    //     .catch(function onError(err) {
    //       //console.log("Error occured..." + err);
    //     });
    // }

    roundChanged(roundID) {
      this.setState({ roundID: roundID });
    }

    render() {
      var player = ewtStore.showPlayerCard;
      var round = ewtStore.rounds[this.state.roundID];
      var roundID = round.id;
      var course = ewtStore.courses[round.courseID];
      var frontPar = 0;
      var backPar = 0;
      var totalPar = 0;
      var frontScore = 0;
      var backScore = 0;
      var totalScore = 0;
      var frontNet = 0;
      var backNet = 0;
      var totalNet = 0;
      var holes = [18];
      for (var i = 0; i < 18; i++) {
        var hole = { num: i + 1 };
        hole.par = course.holes[hole.num].tees[round.tees].par;
        totalPar += hole.par;
        var playerScore = null;
        if (
          ewtStore.roundScores[round.id] &&
          ewtStore.roundScores[roundID][player.id] &&
          ewtStore.roundScores[roundID][player.id][hole.num]
        ) {
          playerScore = ewtStore.roundScores[round.id][player.id][hole.num];
        }
        hole.score = playerScore ? playerScore.score : 0;
        totalScore += hole.score;
        hole.yardage = course.holes[hole.num].tees[round.tees].yardage;
        hole.handicap = course.holes[hole.num].tees[round.tees].handicap;
        hole.net = playerScore ? hole.par + playerScore.strokes : 0;
        totalNet += hole.net;
        if (i < 9) {
          frontPar += hole.par;
          frontScore += hole.score;
          frontNet += hole.net;
        } else {
          backPar += hole.par;
          backScore += hole.score;
          backNet += hole.net;
        }
        holes[i] = hole;
        if (hole.score === 0) hole.score = "";
        if (hole.net === 0) hole.net = "";
      }
      if (frontScore === 0) frontScore = "";
      if (backScore === 0) backScore = "";
      if (backNet === 0) backNet = "";
      if (frontNet === 0) frontNet = "";
      if (totalScore === 0) totalScore = "";
      if (totalNet === 0) totalNet = "";
      var frontHoles = holes.slice(0, 9);
      frontHoles.push({
        num: "Out",
        score: frontScore,
        par: frontPar,
        net: frontNet,
      });
      var backHoles = holes.slice(9, 18);
      backHoles.push({
        num: "In",
        score: backScore,
        par: backPar,
        net: backNet,
      });
      backHoles.push({
        score: totalScore,
        num: "Tot",
        par: totalPar,
        net: totalNet,
      });
      const cellHeader = {
        backgroundColor: "#707070",
        color: "black",
        border: "none",
        padding: 5,
        fontSize: 26,
        textAlign: "center",
      };

      var allHoles = frontHoles.concat(backHoles);
      var netTotal = 0;
      var netTotalStr = "";
      netTotal = ewtStore.roundScores[ewtStore.currentRoundID][player.id].N;
      netTotalStr = ewtStore.roundScores[ewtStore.currentRoundID][player.id].NS;

      return (
        <Modal
          title={
            <div
              className="headerText"
              style={{ width: "100%", padding: 0, margin: 0, height: 85 }}
            >
              <div className="headerText">
                {player.first_name}&nbsp;
                {player.last_name}&nbsp;&nbsp; (
                {player.showRound &&
                  ewtStore.players[ewtStore.showPlayerCard.id].handicaps &&
                  ewtStore.players[ewtStore.showPlayerCard.id].handicaps[
                    ewtStore.tournament.id
                  ]}
                )
              </div>
              <br></br>
              <div className="flexRow" style={{ width: "100%" }}>
                <div className="flexGrow" style={{ width: "100%" }}>
                  {
                    ewtStore.roundScores[ewtStore.currentRoundID][
                      ewtStore.showPlayerCard.id
                    ].P
                  }
                  &nbsp;Place&nbsp;
                  {netTotalStr !== "" && (
                    <nobr
                      style={{
                        color:
                          netTotal < 0
                            ? "red"
                            : netTotal === 0
                            ? "green"
                            : "black",
                      }}
                    >
                      ({netTotalStr})
                    </nobr>
                  )}
                </div>
                {player.pictureURL && (
                  <div
                    style={{
                      marginTop: -10,
                      marginRight: 10,
                      width: 100,
                      height: 65,
                    }}
                  >
                    {!this.state.fullPP && (
                      <img
                        src={player.pictureURL}
                        alt={""}
                        style={{
                          cursor: "pointer",
                          width: 100,
                          height: 65,
                          objectFit: "contain",
                        }}
                        onClick={() =>
                          this.setState({ fullPP: !this.state.fullPP })
                        }
                      ></img>
                    )}
                  </div>
                )}
                {!player.pictureURL &&
                  ewtStore.player &&
                  ewtStore.showPlayerCard.id === ewtStore.player.id && (
                    <Button
                      onClick={() => {
                        ewtStore.showPlayerCard = null;
                        ewtStore.gallery = false;
                        ewtStore.showView = "photo";
                      }}
                    >
                      Add Picture
                    </Button>
                  )}
              </div>
            </div>
          }
          visible={ewtStore.showPlayerCard != null}
          onCancel={() => (ewtStore.showPlayerCard = null)}
          bodyStyle={{
            margin: 0,
            padding: 0,
            height: ewtStore.windowHeight - 130,
            backgroundColor: "#E8E8E8",
          }}
          style={{ top: 0 }}
          closable={true}
          footer={null}
        >
          <div
            className="flexColumn flexGrow"
            style={{ height: window.innerHeight - 130, padding: 5 }}
          >
            {this.state.fullPP && player.pictureURL && (
              <img
                src={player.pictureURL}
                alt={""}
                style={{ width: "100%", height: "auto" }}
                onClick={() => this.setState({ fullPP: !this.state.fullPP })}
              ></img>
            )}

            {!this.state.fullPP && (
              <RoundSelector
                roundID={this.state.roundID}
                handleEvent={this.roundChanged}
              ></RoundSelector>
            )}
            {!this.state.fullPP && (
              <StickyTable
                border={1}
                style={{ fontSize: 19, marginTop: 5, width: "100%" }}
                leftStickyColumnCount={0}
              >
                <Row style={{ width: "100%" }}>
                  <Cell style={cellHeader}>Hole</Cell>
                  <Cell style={cellHeader}>Par</Cell>
                  <Cell style={cellHeader}>
                    <b>Score</b>
                  </Cell>
                  <Cell style={cellHeader}>Hcp</Cell>
                  <Cell style={cellHeader}>Net</Cell>
                  {false && (
                    <>
                      <Cell style={{ width: 50 }}>R1</Cell>
                      <Cell style={{ width: 50 }}>Group Avg</Cell>
                    </>
                  )}
                </Row>
                {ewtStore.course &&
                  ewtStore.scores &&
                  allHoles.map((hole, index) => {
                    return (
                      <Row key={index}>
                        <Cell
                          style={{
                            border: "1px solid lightgray",
                            textAlign: "center",
                          }}
                        >
                          {hole.num}
                        </Cell>
                        <Cell
                          style={{
                            border: "1px solid lightgray",
                            textAlign: "center",
                            padding: 3,
                          }}
                        >
                          {hole.par}
                        </Cell>
                        <Cell
                          style={{
                            border: "1px solid lightgray",
                            textAlign: "center",
                            padding: 3,
                            cursor: "pointer",
                          }}
                          onDoubleClick={() => {
                            if (ewtStore.player.admin) {
                              hole.roundID = round.id;
                              hole.playerID = ewtStore.showPlayerCard.id;
                              ewtStore.correctScore = hole;
                            }
                          }}
                        >
                          <span
                            style={{
                              color: hole.score < hole.par ? "red" : "black",
                            }}
                          >
                            <b>
                              {this.state.editHole !== hole.num && hole.score}
                            </b>
                            {this.state.editHole === hole.num && (
                              <div style={{ width: 50 }}>
                                <input
                                  autoFocus
                                  onFocus={this.handleFocus}
                                  style={{ width: 50 }}
                                  value={this.state.editScore}
                                  onChange={this.editScoreChanged}
                                  onBlur={this.updateScore}
                                ></input>
                              </div>
                            )}
                          </span>
                        </Cell>
                        <Cell
                          style={{
                            border: "1px solid lightgray",
                            textAlign: "center",
                            padding: 3,
                          }}
                        >
                          {hole.handicap}
                        </Cell>
                        <Cell
                          style={{
                            border: "1px solid lightgray",
                            textAlign: "center",
                            padding: 3,
                          }}
                        >
                          <span
                            style={{
                              color: hole.net < hole.par ? "red" : "black",
                            }}
                          >
                            {hole.net}
                          </span>
                        </Cell>

                        {false && (
                          <>
                            <Cell
                              style={{
                                border: "1px solid lightgray",
                                textAlign: "center",
                                padding: 3,
                              }}
                            >
                              {this.state.scoresByHole[hole]
                                ? this.state.scoresByHole[hole].r1
                                : ""}
                            </Cell>
                            <Cell
                              style={{
                                border: "1px solid lightgray",
                                textAlign: "center",
                                padding: 3,
                              }}
                            >
                              {this.state.scoresByHole[hole]
                                ? this.state.scoresByHole[hole].groupAverage
                                : ""}
                            </Cell>
                          </>
                        )}
                      </Row>
                    );
                  })}
              </StickyTable>
            )}
          </div>
        </Modal>
      );
    }
  }
);

export default PlayerCard;
