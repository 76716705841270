import { observable, decorate } from "mobx";
import moment from "moment";

class EWTStore {
  players = null;
  rounds = null;
  courses = null;
  firebaseDB = null;
  firebaseStorage = null;
  showView = "";
  tournament = null;
  player = null;
  course = null;
  chatMessages = null;
  org = null;
  panelHeight = 0;
  panelWidth = 0;
  golfcourseImageURL = null;
  showPlayerCard = null;
  groupPlayers = null;
  windowHeight = window.innerHeight;
  windowWidth = window.innerWidth;
  currentView = "leaderboard";
  color1 = "#1a86b8";
  color2 = "#b86969";
  color3 = "#549c82";
  color4 = "#9f9d4b";
  color5 = "#ae5b42";
  fanMode = false;
  showMap = false;
  courseMapURL = null;
  roundScores = null;
  scores = null;
  currentRoundID = null;
  initialized = false;
  adminUser = false;
  sortedPlayers = null;
  editRounds = false;
  gallery = false;
  unread = { chats: false, pics: false };
  correctScore = null;
  currentRound = {};
  mapIconURL = null;
  showHole = null;
  showFullPic = null;
  holeMapURL = null;
  showCourseMap = false;
  showCourseCard = false;
  showPairings = false;
  logoURL = null;
  deferredInstallPrompt = null;
  usingKeyboard = null;
  usingKeyboardStart = null;
  firstRoundID = null;
  selectTournament = false;

  setCookieValue(key, value) {
    document.cookie =
      key +
      "=" +
      value +
      ";expires=Fri, 30 Dec 2050 12:00:00 UTC;;SameSite=Strict";
  }
  getCookieValue(key) {
    var name = key + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }

  async setOrg(orgID, org) {
    if (org) {
      this.org = org;
      this.setCookieValue(orgID, org.id);
    }
    this.org = await this.getOneDoc("orgs", orgID);
    this.setCookieValue("orgID", this.org.id);
  }

  calcGroup() {
    var round = this.rounds[this.currentRoundID];
    if (!round.players) return null;
    var player = round.players[this.player.id];
    if (!player) return;
    var groupID = player.groupID;
    this.group = round.groups[groupID];
    this.group.id = groupID;
    if (!this.group) alert("Couldn't calculate a group");
    this.groupPlayers = [];
    this.groupplayers = {};
    Object.keys(round.players).forEach((key) => {
      if (round.players[key].groupID === groupID) {
        this.groupPlayers.push(this.players[key]);
        this.groupplayers[key] = this.players[key];
      }
    });
  }

  setRoundOpenStatus(roundID, open) {
    this.firebaseDB.collection("rounds").doc(roundID).update({
      open: open,
    });
    this.rounds[this.currentRoundID].open = open;
  }

  initializeRounds(tournament) {
    var that = this;
    that.firstRoundID = null;
    that.rounds = {};
    return new Promise(function (resolve, reject) {
      that.firebaseDB
        .collection("rounds")
        .where("tournamentID", "==", tournament.id)
        .orderBy("roundNumber")
        .onSnapshot(function (snapshot) {
          // if (!that.rounds) that.rounds = {};
          snapshot.docChanges().forEach(function (change) {
            if (change.type === "removed") {
              delete that.rounds[change.doc.id];
            } else {
              var round = change.doc.data();
              var m = moment(round.date, "MM/DD/YY");
              round.desc = m.format("ddd") + " " + m.format("MM/DD");
              if (round.practice) round.desc += " (P) ";
            }
            round.id = change.doc.id;
            if (round.players) {
              Object.keys(round.players).forEach((playerID) => {
                round.players[playerID].id = playerID;
              });
            }
            //if (round.roundNumber === 1) that.firstRoundID = round.id;
            if (!that.firstRoundID && !round.practice)
              that.firstRoundID = round.id;
            that.rounds[round.id] = round;
          });
          if (that.initialized) that.setSortedPlayers();
          resolve(true);
        });
    });
  }

  showHoleMap(holeNumber) {
    var that = this;
    this.firebaseStorage
      .ref("images/courses/" + this.course.id + "/")
      .child("h" + holeNumber + ".jpg")
      .getDownloadURL()
      .then((url) => {
        that.holeMapURL = url;
      });
    that.showHole = holeNumber;
  }

  initializeScores(tournament) {
    this.roundScores = {};
    this.scores = new Array(500);
    var that = this;
    return new Promise(function (resolve, reject) {
      that.firebaseDB
        .collection("scores")
        .where("tournamentID", "==", tournament.id)
        .onSnapshot(function (snapshot) {
          var needsUpdate = {};
          snapshot.docChanges().forEach(function (change) {
            var score = change.doc.data();
            var roundID = score.roundID;
            var playerID = score.playerID;
            var hole = score.hole;
            if (change.type === "removed") {
              delete that.scores[roundID][playerID][hole];
              if (!needsUpdate[roundID]) needsUpdate[roundID] = {};
              needsUpdate[roundID][playerID] = true;
            } else {
              score.id = change.doc.id;
              if (!that.scores[roundID]) that.scores[roundID] = {};
              if (!that.scores[roundID][playerID])
                that.scores[roundID][playerID] = {};
              that.scores[roundID][playerID][hole] = score;
              if (!needsUpdate[roundID]) needsUpdate[roundID] = {};
              if (!needsUpdate[roundID][playerID])
                needsUpdate[roundID][playerID] = true;
            }
          });
          Object.keys(needsUpdate).forEach((roundID) => {
            if (!that.roundScores[roundID]) that.roundScores[roundID] = {};
            Object.keys(needsUpdate[roundID]).forEach((playerID) => {
              var back = 0;
              var front = 0;
              var holeScores = {};
              for (var i = 0; i < 18; i++) {
                var hole = i + 1;
                var playerScore = that.scores[roundID][playerID][hole];
                if (playerScore) {
                  var score = playerScore.score;
                  if (hole < 10) front += score;
                  if (hole >= 10) back += score;
                  var strokes = that.getNet(
                    playerID,
                    that.courses[that.rounds[roundID].courseID],
                    that.rounds[roundID],
                    hole,
                    score
                  );
                  playerScore.strokes = strokes;
                  holeScores[i + 1] = playerScore;
                }
              }
              holeScores["B"] = back;
              holeScores["F"] = front;
              holeScores["T"] = back + front;
              that.roundScores[roundID][playerID] = holeScores;
            });
          });
          that.setSortedPlayers();
          resolve(true);
        });
    });
  }

  getCurrentRounds() {
    var rounds = [];
    var currentRound = this.rounds[ewtStore.currentRoundID];
    if (currentRound.practice) {
      //if it's a practice round - only show that everywhere
      rounds = [currentRound];
    } else {
      Object.keys(this.rounds).forEach((roundID) => {
        var round = this.rounds[roundID];
        if (!round.practice) {
          //only use real rounds
          if (round.roundNumber <= currentRound.roundNumber) {
            rounds.push(round);
          }
        }
      });
    }
    return rounds;
  }

  setSortedPlayers() {
    var rounds = this.getCurrentRounds();
    var currentRound = this.rounds[ewtStore.currentRoundID];
    var players = {};
    Object.keys(this.players).forEach((playerID) => {
      var player = { id: playerID, pictureURL: this.players[playerID].pictureURL };
      player.total = 9999;
      player.grossScore = 0;
      player.through = 0;
      rounds.forEach((round) => {
        if (round.roundNumber === currentRound.roundNumber) {
          if (round.groups && round.players && round.players[playerID]) {
            var roundPlayer = round.players[playerID];
            var groupID = roundPlayer.groupID;
            var roundGroup = round.groups[groupID];
            player.groupNumber = roundGroup ? roundGroup.groupNumber : -1;
            player.group = roundGroup;
          }
        }
        var playerScores = null;
        if (this.roundScores[round.id])
          playerScores = this.roundScores[round.id][playerID];
        if (playerScores) {
          var roundNet = 0;
          for (var i = 0; i < 18; i++) {
            var hole = i + 1;
            if (playerScores[hole]) {
              if (player.total === 9999) player.total = 0;
              player.grossScore += playerScores[hole].score;
              player.total += playerScores[hole].strokes;
              roundNet += playerScores[hole].strokes;
              //if current round log the last hole the player has a score for
              if (round.roundNumber === currentRound.roundNumber) {
                if (playerScores[hole].score && hole > player.through)
                  player.through = hole;
              }
            }
          }
          if (round.id === this.currentRoundID) {
            player.currentRoundNet = roundNet;
            if (player.currentRoundNet > 0)
              player.currentRoundNetStr = "+" + player.currentRoundNet;
            if (player.currentRoundNet < 0)
              player.currentRoundNetStr = player.currentRoundNet;
            if (player.currentRoundNet === 0) player.currentRoundNetStr = "E";
            if (player.currentRoundNet === 9999) player.currentRoundNetStr = "";
          }
        }
      });
      players[player.id] = player;
    });
    var that = this;
    var playersArr = Object.keys(players).map((playerID) => players[playerID]);
    var sortedPlayers = playersArr.sort(function (a, b) {
      if (a.total !== b.total) {
        // console.log("total " + a.id + "/" + b.id);
        return a.total - b.total;
      }
      if (!that.rounds[that.currentRoundID].players) return 0;
      var aGroupNumber = players[a.id].groupNumber;
      var bGroupNumber = players[b.id].groupNumber;
      if (!aGroupNumber) aGroupNumber = 999;
      if (!bGroupNumber) bGroupNumber = 999;
      if (aGroupNumber === bGroupNumber) {
        return 0;
      }
      if (aGroupNumber < bGroupNumber) {
        return -1;
      }
      return 1;
    });
    var place = 1;
    var lastScore = -99999;
    var prefix = null;
    var nextScore = null;
    sortedPlayers.forEach((player, index) => {
      prefix = "";
      nextScore = -1;
      if (index < sortedPlayers.length - 1)
        nextScore = sortedPlayers[index + 1].total;
      if (player.total > lastScore) {
        place = index + 1;
      }
      if (player.total === lastScore || player.total === nextScore) {
        prefix = "T";
      }
      player.position = prefix + place;
      // if (place === 1) player.position += "st";
      // if (place === 2) player.position += "nd";
      // if (place === 3) player.position += "rd";
      // if (place > 3) player.position += "th";
      if (!this.roundScores[ewtStore.currentRoundID])
        ewtStore.roundScores[ewtStore.currentRoundID] = {};
      if (!this.roundScores[ewtStore.currentRoundID][player.id])
        ewtStore.roundScores[ewtStore.currentRoundID][player.id] = {};
      this.roundScores[ewtStore.currentRoundID][player.id]["P"] =
        player.position;
      lastScore = player.total;
      if (player.total > 0) player.totalStr = "+" + player.total;
      if (player.total < 0) player.totalStr = player.total;
      if (player.total === 0) player.totalStr = "E";
      if (player.total === 9999) player.totalStr = "";
      this.roundScores[this.currentRoundID][player.id]["N"] = player.total;
      this.roundScores[this.currentRoundID][player.id]["NS"] = player.totalStr;
    });
    this.sortedPlayers = sortedPlayers;
  }

  deleteOneDoc(collectionName, docID) {
    this.firebaseDB.collection(collectionName).doc(docID).delete();
  }

  deleteScore(hole) {
    if (
      this.roundScores[hole.roundID] &&
      this.roundScores[hole.roundID][hole.playerID] &&
      this.roundScores[hole.roundID][hole.playerID][hole.num]
    ) {
      var score = this.roundScores[hole.roundID][hole.playerID][hole.num];
      this.firebaseDB.collection("scores").doc(score.id).delete();
    }
  }

  async setScore(roundID, playerID, holeNumber, newScore) {
    var that = this;
    return new Promise(function (resolve, reject) {
      if (
        that.roundScores[roundID] &&
        that.roundScores[roundID][playerID] &&
        that.roundScores[roundID][playerID][holeNumber]
      ) {
        var score = that.roundScores[roundID][playerID][holeNumber];
        that.firebaseDB
          .collection("scores")
          .doc(score.id)
          .update({
            score: newScore,
          })
          .then((doc) => {
            resolve();
          });
      } else {
        var score2 = {
          roundID: roundID,
          playerID: playerID,
          hole: holeNumber,
          tournamentID: that.tournament.id,
          score: newScore,
        };
        that.firebaseDB
          .collection("scores")
          .add(score2)
          .then((doc) => {
            resolve();
          });
      }
    });
  }

  async setCourse(courseID) {
    var course = await this.getOneDoc("courses", courseID);
    this.course = course;
    if (!course) alert("invalid courseID" + courseID);
    if (!this.fanMode) this.calcGroup();
    const cs = await this.getAllDocs("courses");
    this.courses = {}
    for (const c of cs) {
      this.courses[c.id] = c;
    }
  }

  setFanMode(mode) {
    ewtStore.fanMode = mode;
    this.player = null;
    if (mode) this.setCookieValue("playerID", "");
    this.setCookieValue("fanMode", mode);
    if (mode) this.currentView = "leaderboard";
  }

  updateOneDoc(collectionName, id, obj) {
    var that = this;
    return new Promise(function (resolve, reject) {
      that.firebaseDB
        .collection(collectionName)
        .doc(id)
        .update(obj)
        .then((doc) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addOneDoc(collectionName, obj) {
    var that = this;
    return new Promise(function (resolve, reject) {
      that.firebaseDB
        .collection(collectionName)
        .add(obj)
        .then((doc) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOneDoc(collectionName, id) {
    var that = this;
    return new Promise(function (resolve, reject) {
      that.firebaseDB
        .collection(collectionName)
        .doc(id)
        .get()
        .then((doc) => {
          var obj = doc.data();
          obj.id = doc.id;
          resolve(obj);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  async setCurrentRound(roundID) {
    await this.updateOneDoc("tournaments", this.tournament.id, {
      currentRoundID: roundID,
    });
    return new Promise(function (resolve, reject) {
      // that.setSortedPlayers();
      resolve();
    });
  }

  async getAllDocs(collectionName) {
    const snapshot = await this.firebaseDB.collection('courses').get();
    return snapshot.docs.map(doc => { let x = doc.data();x.id = doc.id;return x});
  }

  async setCurrentRound(roundID) {
    await this.updateOneDoc("tournaments", this.tournament.id, {
      currentRoundID: roundID,
    });
    return new Promise(function (resolve, reject) {
      // that.setSortedPlayers();
      resolve();
    });
  }

  async setTournament(tournamentID, tournament, change) {
    if (change && tournamentID === this.tournament.id) return;
    this.initialized = false;
    if (!tournament)
      tournament = await this.getOneDoc("tournaments", tournamentID);
    this.setOrg(tournament.orgID, null);
    this.tournament = tournament;
    this.currentRoundID = tournament.currentRoundID;
    await this.loadPlayers(tournament);
    await this.initializeRounds(tournament);
    await this.setCourse(this.rounds[this.currentRoundID].courseID);
    await this.initializeScores(tournament);
    var that = this;
    that.firebaseDB
      .collection("tournaments")
      .doc(tournamentID)
      .onSnapshot(function (doc) {
        var t = doc.data();
        t.id = doc.id;
        var recalc = that.tournament.currentRoundID !== t.currentRoundID;
        that.tournament = t;
        that.currentRoundID = that.tournament.currentRoundID;
        that.currentRound = that.rounds[that.currentRoundID];
        if (recalc) {
          that.setSortedPlayers();
        }
        return new Promise(function (resolve, reject) {
          that.initialized = true;
          resolve();
        });
      });
  }
  async setPlayer(playerID, player) {
    var that = this;
    return new Promise(async function (resolve, reject) {
      if (ewtStore.fanMode) return;
      if (!player) {
        player = await that.getOneDoc("players", playerID);
      }
      if (player.admin) that.adminUser = true;
      that.setCookieValue("playerID", player.id);
      var tournaments = [];
      for (var i = 0; i < player.tournaments.length; i++) {
        var tournament = await that.getOneDoc(
          "tournaments",
          player.tournaments[i]
        );
        tournaments.push(tournament);
      }
      player.fullTournaments = tournaments;
      that.player = player;
      resolve();
    });
  }
  valueInArray(arr, value) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === value) return i;
    }
    return null;
  }
  isInitialized() {
    return this.initialized;
  }
  getNet(playerID, course, round, holeNumber, score) {
    if (!this.players[playerID]) return;
    var courseHole = course.holes[holeNumber];
    var tees = round.tees;
    var tee = courseHole.tees[tees];
    if (score > tee.par * 2) score = tee.par * 2; //max for the handicap
    var holeHandicap = tee.handicap;
    var relativeScore = score - tee.par;
    var playerHandicap = this.players[playerID].handicaps
      ? this.players[playerID].handicaps[this.tournament.id]
      : 0; //this.players[playerID].handicap;
    var offset = 0;
    if (playerHandicap >= 18) offset = offset - 1;
    if (playerHandicap >= 36) offset = offset - 1;
    if (playerHandicap >= 54) offset = offset - 1;
    playerHandicap = playerHandicap % 18;
    if (playerHandicap >= holeHandicap) offset = offset - 1;
    relativeScore += offset;
    return relativeScore;
  }
  loadPlayers(tournament) {
    this.players = {};
    var that = this;
    return new Promise(function (resolve, reject) {
      ewtStore.firebaseDB
        .collection("players")
        .where("tournaments", "array-contains", tournament.id)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach(function (change) {
            if (change.type === "removed") {
              delete that.players[change.doc.id];
            } else {
              var player = change.doc.data();
              player.id = change.doc.id;
              if (!player.initials)
                player.initials =
                  player.first_name.substr(0, 1) +
                  player.last_name.substr(0, 1);
              if (!player.shortName) {
                player.shortName = player.first_name.substr(0, 1) + ". " + player.last_name;
                for (const pid of Object.keys(that.players)) {
                  const p = that.players[pid];
                  if (p.id != player.id && p.shortName && p.shortName === player.shortName) {
                    player.shortName = player.first_name.substr(0, 2) + ". " + player.last_name;
                    p.shortName = p.first_name.substr(0, 2) + ". " + p.last_name;
                  }
                }
              }
              if (!that.players) that.players = {};
              that.players[player.id] = player;
              ewtStore.firebaseStorage
                .ref("images/playerpics/t" + tournament.id)
                .child("pp" + player.id + ".png")
                .getDownloadURL()
                .then((url) => {
                  that.players[player.id].pictureURL = url;
                })
                .catch(function onError(err) {
                  //console.log("Error occured..." + err);
                });
            }
          });
          if (that.initialized) {
            that.setSortedPlayers();
          }
          resolve();
        });
    });
  }
}
decorate(EWTStore, {
  players: observable,
  scores: observable,
  tournament: observable,
  chatMessages: observable,
  org: observable,
  rounds: observable,

  groupPlayers: observable,
  player: observable,
  roundScores: observable,
  sortedPlayers: observable,

  course: observable,
  showPlayerCard: observable,
  currentView: observable,
  editRounds: observable,
  gallery: observable,
  unread: observable,
  correctScore: observable,
  showHole: observable,
  showFullPic: observable,
  holeMapURL: observable,
  showCourseMap: observable,
  showCourseCard: observable,
  showPairings: observable,

  windowWidth: observable,
  showView: observable,
  windowHeight: observable,

  showMap: observable,
  golfcourseImageURL: observable,
  courseMapURL: observable,
  currentRoundID: observable,
  currentRound: observable,
  mapIconURL: observable,

  fanMode: observable,
  initialized: observable,
  adminUser: observable,
  logoURL: observable,
  deferredInstallPrompt: observable,
  usingKeyboad: observable,
  firstRoundID: observable,
  isInitialized: observable,
  selectTournament: observable,
});

const ewtStore = new EWTStore();
//ewtStore.players = ["fred","bob"]
export default ewtStore;
