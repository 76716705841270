import React from 'react';
import ewtStore from '../EWTStore';
import { observer } from "mobx-react"
import { Input, Button } from 'antd';
import { PlayerDetails } from './PlayerDetails';
import { DataTournaments } from './DataTournaments';

const { TextArea } = Input;

const Data = observer(class Data extends React.Component {
	constructor(props) {
		super(props);
		this.state = { value: null, players: null, button: null, selectedPlayerIndex: -1 }
		this.collectionName = null;
		this.key = null;
		this.getKey = null;
		this.getCollectionName = null;
		this.textAreaRef = React.createRef();
		this.save = this.save.bind(this);
		this.get = this.get.bind(this);
		this.createPlayer = this.createPlayer.bind(this);
		this.savePlayer = this.savePlayer.bind(this);
		this.getPlayers();
	}

	get() {
		if (!this.getKey || !this.getCollectionName) return;
		var that = this;
		ewtStore.firebaseDB.collection(this.getCollectionName).doc(this.getKey).get().then((doc) => {
			that.setState({ "value": JSON.stringify(doc.data()) });
		})
	}

	save() {
		if (!this.collectionName) return;
		var val = this.state.value;
		val = val.replace("now", new Date().getTime());
		ewtStore.firebaseDB.collection(this.collectionName).doc(this.key).set(JSON.parse(val), function (err) {
			alert(err);
		})
	}

	createPlayer() {
		const newPlayer = {tournaments:[],handicaps:{}}
		let newPlayers = [...this.state.players];
		newPlayers.push(newPlayer);
		this.setState({players:newPlayers,selectedPlayerIndex:newPlayers.length - 1})
	}

	savePlayer(player) {
		delete player.tournamentHandicaps;
		if (player.id) {
			ewtStore.updateOneDoc("players",player.id,player);
		} else {
			const newID =  (Math.max(...this.state.players.map(o => o.id?parseInt(o.id):0)) + 1).toString();
			player.id = newID;
			player.org = "1"; //hard-coded
			if (player.first_name && player.last_name) player.code = player.first_name.substr(0,2).toUpperCase() + player.last_name.substr(0,1).toUpperCase() + newID;
			ewtStore.firebaseDB.collection("players").doc(newID).set(player);
		}
		let newPlayers = this.state.players;
		newPlayers[this.state.selectedPlayerIndex] = player;
		this.setState({players:newPlayers});
	}

	async getPlayers() {
		const snapshot = await ewtStore.firebaseDB.collection('players').orderBy("last_name").orderBy("first_name").get();
		const players = snapshot.docs.map((doc) => { const x = doc.data(); x.id = doc.id; return x });
		this.setState({ players: players, button: "D" });
	}


	render() {
		if (!this.state.button === null) return <div>Retrieving</div>;
		return (
			<div className="flexColumn" style={{ marginTop: 10, marginLeft: 10 }}>
				<div className="flexRow">
					<Button style={{ width: 150 }} onClick={() => this.setState({ value: this.state.value, button: "P" })} type={this.state.button === "P" ? "primary" : ""}>Players</Button>
					<Button style={{ width: 150 }} onClick={() => this.setState({ value: this.state.value, button: "T" })} type={this.state.button === "T" ? "primary" : ""}>Tournaments</Button>
					<Button style={{ width: 150 }} onClick={() => this.setState({ value: this.state.value, button: "D" })} type={this.state.button === "D" ? "primary" : ""}>Raw Data</Button>
				</div>
				{this.state.button === "D" && <div className="flexRow">
					<div>
						Key<div style={{ width: 100 }}><input onChange={(evt) => this.key = evt.target.value}></input></div>
						<br></br>Collection Name<div style={{ width: 100 }}><input onChange={(evt) => this.collectionName = evt.target.value}></input></div>
						<br></br>Value<div style={{ width: 300 }}><TextArea ref={this.textAreaRef} value={this.state.value} onChange={(evt) => this.setState({ "value": evt.target.value })} rows={10}>

						</TextArea></div>
						<br></br><div style={{ width: 100 }}><input type="button" value="Save" onClick={this.save} style={{ cursor: 'pointer' }}></input></div>
					</div>
					<div>
						Key<div style={{ width: 100 }}><input onChange={(evt) => this.getKey = evt.target.value}></input></div>
						<br></br>Collection Name<div style={{ width: 100 }}><input onChange={(evt) => this.getCollectionName = evt.target.value}></input></div>
						<br></br><div style={{ width: 100 }}><input type="button" value="Get" onClick={this.get} style={{ cursor: 'pointer' }}></input></div>
					</div>
				</div>}
				{this.state.button === "P" && <div>
					<Button style={{ width: 150, marginTop: 15 }} onClick={this.createPlayer}>Create Player</Button>

				<div className="flexRow">
					<div className="flexScroll" style={{ height: 550,width:475 }}>
						{this.state.players.map((player, index) => {
							return (
								<div key={index} className="flexRow" style={{ border: '1px solid lightgray', padding: 5, width: 450, marginTop: 10, cursor: 'pointer', backgroundColor: index === this.state.selectedPlayerIndex ? 'lightblue' : '' }} 
									onClick={() => this.setState({ selectedPlayerIndex: index })} >
									<div style={{ width: 125 }}>{player.last_name}</div>
									<div style={{ width: 125 }}>{player.first_name}</div>
									<div style={{ width: 125 }}>{player.nickname}</div>
								</div>
							)
						})}
					</div>
					{this.state.selectedPlayerIndex >= 0 && <div>
						<PlayerDetails player={this.state.players[this.state.selectedPlayerIndex]} savePlayer={this.savePlayer}></PlayerDetails>
					</div>}
					</div>
				</div>}
			{this.state.button === "T" && <DataTournaments players={this.state.players}></DataTournaments>}
			</div>
		);
	}
});


export default Data;