import React from 'react';
import { observer } from "mobx-react"
import ewtStore from '../EWTStore';
import {CaretDownFilled,LockOutlined,EditFilled} from '@ant-design/icons';
import {Menu,Dropdown} from 'antd';

const RoundSelector = observer(class RoundSelector extends React.Component {
	constructor(props) {
		super(props);
		var roundID = props.roundID;
		if (roundID === "" && ewtStore.currentRoundID) roundID = ewtStore.currentRoundID;
		this.state = {roundID:roundID}
	}

	render() {
		const menu = (<Menu style={{width:240}}>
			{Object.keys(ewtStore.rounds).map((roundID,index) => {
				return (<Menu.Item key={index} onClick={() => {this.setState({roundID:roundID});this.props.handleEvent(roundID)}}>
							<div style={{fontSize:26}} className="flexRow">
								<div style={{width:40}}>
									{ewtStore.rounds[roundID].finalized && <LockOutlined style={{fontSize:26}}/>}
									{ewtStore.rounds[roundID].open && !ewtStore.rounds[roundID].finalized && <EditFilled style={{fontSize:26}}/>}
								</div>
								{ewtStore.rounds[roundID].desc}
							</div>
						</Menu.Item>
				)
			})}
		</Menu>);
		var round = ewtStore.rounds[this.state.roundID];

		return (
			<div style={{marginTop:5}}>
				<Dropdown overlay={menu} trigger={['click']}>
					<div style={{height:45,borderRadius:10,marginTop:-5,marginLeft:5,fontSize:24,cursor:'pointer',backgroundColor:'#F5F5F5',border:'1px solid black',padding:5,maxWidth:250}} className="flexRow">
						<div style={{width:40,marginTop:-5}}>
							{round.finalized && <LockOutlined style={{fontSize:26,marginTop:5}}/>}
							{round.open && !round.finalized && <EditFilled style={{fontSize:26,marginTop:5}}/>}
						</div>
						<span style={{marginTop:-5}}>{round && round.desc}&nbsp;<CaretDownFilled style={{marginTop:5}}/></span>
					</div>
				</Dropdown>
			</div>
	  	);
	}
});


export default RoundSelector;