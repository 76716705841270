import React from "react";
import { observer } from "mobx-react";
import ewtStore from "../EWTStore";
import { reaction } from "mobx";
import { StickyTable, Row, Cell } from "react-sticky-table";
import RoundSelector from "./RoundSelector";

const Pairings = observer(
  class Pairings extends React.Component {
    constructor(props) {
      super(props);
      const reaction1 = reaction(
        () => ewtStore.currentRoundID,
        (currentRoundID) => this.setState({ roundID: currentRoundID })
      );
      this.state = {
        roundID: ewtStore.currentRoundID,
        currentRoundReaction: reaction1,
        players:null,
        round:null
      };
      this.roundChanged = this.roundChanged.bind(this);
    }
    roundChanged(roundID) {
      this.setState({ roundID: roundID });
      var round = ewtStore.rounds[roundID];
      this.setState({round:round});
      var playerIDs = round.players;
      let players = null;
      if (round.players) {
        players = Object.keys(playerIDs).map((playerID) => {
          var p = round.players[playerID];
          return p;
        });
        players = players.sort(function (a, b) {
          if (a.groupID === b.groupID) return 0;
          if (a.groupID > b.groupID) return 1;
          return -1;
        });
      } else {
        players = [];
        Object.keys(round.groups).forEach((groupID) => {
          players.push({ id: -1, groupID: groupID });
        });
      }
      this.setState({players:players})
    }

    componentWillUmount() {
      if (this.state.currentRoundReaction)
        this.state.currentRoundReaction.dispose();
    }

    componentDidMount() {
      this.roundChanged(ewtStore.currentRoundID);
    }

    render() {
      const players = this.state.players;
      const round = this.state.round;
      if (!round) return <div></div>;
      const cellHeader = {
        backgroundColor: "#5FA052",
        border: "none",
        padding: 5,
        fontSize: 26,
      };
      var lastGroupID = 0;
      var newGroup = false;
      return (
        <div
          className="flexColumn flexGrow"
          style={{
            height: this.props.all
              ? ewtStore.panelHeight + 100
              : ewtStore.panelHeight - 50,
          }}
        >
          {!round.players && (
            <div>
              <h1>
                Pairings TBD<br></br>Determined by Standings
              </h1>
            </div>
          )}
          {this.props.all && (
            <RoundSelector
              roundID={this.state.roundID}
              handleEvent={this.roundChanged}
            ></RoundSelector>
          )}
          <span style={{ marginLeft: 5, fontSize: 30 }}>
            {round.name}&nbsp;{round.tees}&nbsp;Tees
          </span>
          {ewtStore.players && (
            <StickyTable border={1} style={{ fontSize: 19 }}>
              <Row>
                <Cell style={cellHeader}>#</Cell>
                <Cell style={cellHeader}>Time</Cell>
                <Cell style={cellHeader}>Player</Cell>
              </Row>
              {round &&
                players.map((player, index) => {
                  newGroup = player.groupID !== lastGroupID;
                  const pictureURL = ewtStore.players[player.id].pictureURL;
                  lastGroupID = player.groupID;
                  return (
                    <Row key={index}>
                      <Cell
                        style={{
                          border: "1px solid lightgray",
                          textAlign: "center",
                        }}
                      >
                        {newGroup && player.groupID}
                      </Cell>
                      <Cell
                        style={{
                          border: "1px solid lightgray",
                          textAlign: "center",
                        }}
                      >
                        {newGroup &&
                          round.groups[player.groupID] &&
                          round.groups[player.groupID].teeTime}
                      </Cell>
                      {ewtStore.players[player.id] && (
                        <Cell
                          style={{
                            border: "1px solid lightgray",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            ewtStore.players[player.id].showRound = round;
                            ewtStore.showPlayerCard =
                              ewtStore.players[player.id];
                          }}
                        >
                          {player.id !== -1 && (
                            <div style={{display:'flex',flexDirection:'row'}}>
                              <div style={{width:190}}>
                                {ewtStore.players[player.id].first_name}&nbsp;
                                {ewtStore.players[player.id].last_name}
                                &nbsp;{ewtStore.players[player.id].position}
                                <br></br>
                                <b>{ewtStore.players[player.id].nickname}</b>
                                &nbsp;&nbsp;Hcp&nbsp;
                                {
                                  ewtStore.players[player.id].handicaps[
                                    ewtStore.tournament.id
                                  ]
                                }
                              </div>
                              {pictureURL && <img
                                src={pictureURL}
                                alt={""}
                                style={{
                                  cursor: "pointer",
                                  width: 100,
                                  height: 65,
                                  objectFit: "contain",
                                }}
                                onClick={(e) =>
                                  {e.stopPropagation();this.setState({ fullPP: this.state.fullPP?null:pictureURL})}
                                }
                              />}
                            </div>
                          )}
                        </Cell>
                      )}
                    </Row>
                  );
                })}
            </StickyTable>
          )}
            {this.state.fullPP && (
              <img
                src={this.state.fullPP}
                alt={""}
                style={{ width: "300px", height: "auto" }}
                onClick={() => this.setState({ fullPP: null })}
              ></img>
            )}


        </div>
      );
    }
  }
);

export default Pairings;
