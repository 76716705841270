import React from 'react';
import { observer } from "mobx-react"
import ewtStore from '../EWTStore';
import RoundSelector from './RoundSelector';
import {Checkbox, Button, Popconfirm, Modal} from 'antd';

const RoundMaint = observer(class RoundMaint extends React.Component {
	constructor(props) {
		super(props);
		this.state = {roundID:ewtStore.currentRoundID}
		this.roundChanged = this.roundChanged.bind(this);
		this.currentRoundChanged = this.currentRoundChanged.bind(this);
		this.openChanged = this.openChanged.bind(this);
		this.lockChanged = this.lockChanged.bind(this);
		this.pairingsConfirmed = this.pairingsConfirmed.bind(this);
	}

	roundChanged(roundID) {
		this.setState({roundID:roundID});
	}

	currentRoundChanged(e) {
		if (e.target.checked) {
			ewtStore.setCurrentRound(this.state.roundID);
		}
	}

	openChanged(e) {
		ewtStore.setRoundOpenStatus(this.state.roundID,e.target.checked);
	}

	lockChanged(e) {
		ewtStore.updateOneDoc("rounds",this.state.roundID,{finalized:e.target.checked});
	}

	pairingsConfirmed() {
		var sortedPlayers = ewtStore.sortedPlayers;
		var round = ewtStore.rounds[this.state.roundID];
		var groupIDs = Object.keys(round.groups).map((groupID) => groupID);
		var inGroup = 0;
		var currentGroup = 0;
		var players = {};
		for (var i=sortedPlayers.length-1;i>=0;i--) {
			var player = sortedPlayers[i];
			players[player.id] = {groupID:groupIDs[currentGroup]};
			inGroup++;
			if (inGroup === 4) {
				currentGroup++;
				inGroup = 0;
			}
		}
		ewtStore.updateOneDoc("rounds",round.id,{players:players}).then(() => {
		});
	}

	render() {
		return (
			<Modal
				title={<div className='flexRow' style={{width:'100%',fontSize:26}}>
						Edit Round Info</div>}
				visible={ewtStore.editRounds}
				bodyStyle={{margin:0,padding:0,maxHeight:300,width:300}}
				style={{top:0}}
				closable={true}
				onCancel={() => ewtStore.editRounds = false}
				footer={null}
				>
					<div className="flexColumn flexGrow" style={{height:window.innerHeight - 110,padding:5,fontSize:26}}>
						<RoundSelector roundID={this.state.roundID} handleEvent={this.roundChanged}></RoundSelector>
						<br></br>
						<Checkbox style={{marginLeft:8,fontSize:26}} disabled={ewtStore.currentRoundID === this.state.roundID} onChange={this.currentRoundChanged} checked={ewtStore.currentRoundID === this.state.roundID}>Current Round</Checkbox>
						<Checkbox style={{fontSize:26}} onChange={this.openChanged} checked={ewtStore.rounds[this.state.roundID].open}>Open for Scoring</Checkbox>
						<Checkbox style={{fontSize:26}} onChange={this.lockChanged} checked={ewtStore.rounds[this.state.roundID].finalized}>Lock Round</Checkbox>
						<br></br>
						{!ewtStore.rounds[this.state.roundID].fixedPairings && <Popconfirm 
							title="All existing pariings will be replaced.  Are you sure you want to do that?"
							onConfirm={this.pairingsConfirmed}
							okText="Yes"
							cancelText="No"
						>
							<Button type="primary" style={{width:150,marginLeft:7}} size="large">Generate Pairings</Button>
						</Popconfirm>}
					</div>
			</Modal>
		);
	}
});


export default RoundMaint;