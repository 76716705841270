import React from 'react';
import { observer} from "mobx-react";
import ewtStore from '../EWTStore';
import moment from 'moment';
import {Button, Input, Modal,List} from 'antd';
import {CommentOutlined,SendOutlined,DeleteOutlined,SyncOutlined,CameraOutlined,FireOutlined,FireFilled} from '@ant-design/icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import PullToRefresh from 'react-simple-pull-to-refresh';
import InfiniteScroll from 'react-bidirectional-infinite-scroll';
import golfball from '../golfball.png';
import chirp from '../chirp.png';

const { TextArea } = Input;

const circle = {minWidth:35,width:35,height:35,borderRadius:'50%',cursor:'pointer',fontSize:12,
				lineHeight:'35px',textAlign:'center',backgroundImage:`url(${golfball})`,position:'relative'}

class Chat extends React.Component {

	constructor(props) {
		super(props);
		this.state = {commentBeingEntered:"",validComment:false,messageComments:{},allComments:{},firstNewMessageID:null,firstNewMessageRef:null,infiniteScrollRef:React.createRef(),
						items: [],count:0,mode:this.props.mode,addCommentMessageID:null,
						commentRef:React.createRef(),commentsRef:React.createRef(),addCommentRef:React.createRef(),messages:{},nowInterval:null,now:new moment()}
		this.myRef = React.createRef();
		this.unsubscribeSnapshot = null;
		this.numMessages = 4;
		this.chirp = false;
		this.lastChatDoc = null;
		this.firstChatDoc = null;
		this.handleScroll = this.handleScroll.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);
		this.commentKeyUp = this.commentKeyUp.bind(this);
		this.addComment = this.addComment.bind(this);
		this.deleteComment = this.deleteComment.bind(this);
		this.showComments = this.showComments.bind(this);
		this.showAllComments = this.showAllComments.bind(this);
		this.likeMessage = this.likeMessage.bind(this);

	}


	componentDidMount() {
		var that = this;
		var nowInterval = setInterval(() => that.setState({now:new moment()}),2000);
		this.setState({nowInterval:nowInterval});
		if (ewtStore.isInitialized()) {
			this.initializeFB();
		}
	}

	componentWillUnmount() {
		if (this.state.nowInterval) window.clearInterval(this.state.nowInterval);
	}

	initializeFB() {
		var that = this;
		ewtStore.firebaseDB.collection(this.state.mode).where("tournamentID","==",ewtStore.tournament.id).where("when","<",new Date().getTime()).orderBy("when","desc").limit(this.numMessages).onSnapshot((snapshot) => {
			if (snapshot.size === 0) return;
			that.firstChatDoc = snapshot.docs[0];
			that.lastChatDoc = snapshot.docs[snapshot.size - 1];
			this.handleNewMessages(snapshot,"init");
		});
	  }

	  handleNewMessages(snapshot,source) {
		var that = this;
		var firstNewMessageID = null;

		var messages = this.state.messages;
		snapshot.docChanges().forEach(function(change) {
			var mess = change.doc.data();
			mess.id = change.doc.id;
			if (!firstNewMessageID) firstNewMessageID = mess.id;
			if (change.type === "added") {
				that.prepPicURL(mess);
				mess.ref = React.createRef();
				messages[mess.id] = mess;
				that.showComments(mess);
				that.prepPicURL(mess);
			} else if (change.type === "removed") {
					delete messages[mess.id];
			} else {
				Object.assign(messages[mess.id],mess);
			}
		});
		that.setState({messages:messages});

		var ref = React.createRef();
		this.setState({firstNewMessageID:firstNewMessageID,firstNewMessageRef:ref});
		// this.messages = Object.assign(this.messages,newObj);
		if (this.state.firstNewMessageRef.current) this.state.firstNewMessageRef.current.scrollIntoView({behavior:"smooth"});
	}


	handleScrollUp = () => {
		ewtStore.firebaseDB.collection(this.state.mode).where("tournamentID","==",ewtStore.tournament.id).orderBy("when","asc").startAfter(this.firstChatDoc).limit(this.numMessages).onSnapshot((querySnapshot) => {
			if (querySnapshot.size === 0) return;
			this.firstChatDoc = querySnapshot.docs[0];
			this.handleNewMessages(querySnapshot,"up");
		});
	}

	handleScrollDown = () => {
		// if (this.first) return;
		// if (!this.lastChatDoc) return;
		var that = this;
		//var since = new Date().getTime();
		ewtStore.firebaseDB.collection(this.state.mode).where("tournamentID","==",ewtStore.tournament.id).orderBy("when","desc").startAfter(this.lastChatDoc).limit(this.numMessages).onSnapshot((querySnapshot) => {
			that.lastChatDoc = null;
			if (querySnapshot.size === 0) {
				return;
			}
			this.lastChatDoc = querySnapshot.docs[querySnapshot.size - 1];
			this.handleNewMessages(querySnapshot,"down");
		});
	}

	handleScroll() {
		var ele = this.state.infiniteScrollRef.current.scroller;
		var bottom = (((ele.scrollHeight - ele.scrollTop) - ele.offsetHeight) < 5);
		if (bottom) this.handleScrollDown();
	}

	handleRefresh(resolve) {
		this.handleScrollUp();
		this.sleep(500);
		return new Promise(res => {res()});
	}


	prepPicURL(message) {
		if (!message.name) return;
		var that = this;
		ewtStore.firebaseStorage.ref("images/gallery/").child(message.name + ".png").getDownloadURL().then(url => {
			that.state.messages[message.id].url = url;
		  }).catch(function onError(err) {
			  console.log(message.id);
			console.log("Error occured getting image url");
			console.dir(err);
		  });;
	}

	  confirmedDelete(message) {
		if (this.state.mode === "pics") {
			var ref = ewtStore.firebaseStorage.ref("images/gallery/").child(message.name + ".png");
			ref.delete().then(function() {
				// File deleted successfully
			  }).catch(function(error) {
				console.log("error deleting" + error);
			  });
	
		  }
		ewtStore.firebaseDB.collection(this.state.mode).doc(message.id).delete();
		var messages = this.state.messages;
		delete messages[message.id];
		this.setState({messages:messages});
		var batch = ewtStore.firebaseDB.batch();
		ewtStore.firebaseDB.collection("comments").where("messageID","==",message.id).get().then((snapshot) => {
			snapshot.forEach(doc => {
				batch.delete(doc.ref); 
			});
			batch.commit();
		});
	}

	deleteMessage(message) {
		if (ewtStore.fanMode) return;
		if (message.playerID !== ewtStore.player.id  && !ewtStore.adminUser) return;
		confirmAlert({
			title: 'Confirm Delete',
			message: 'Are you sure you are ready to delete this?',
			buttons: [
			  {
				label: 'Yes',
				onClick: () => this.confirmedDelete(message)
			  },
			  {
				label: 'No',
			  }
			]
		  })
	}

	async addComment(messageID) {
		var ref = this.state.addCommentRef;
		var value = this.state.commentBeingEntered;
		if (!value || value === "") return;
		this.setState({addCommentMessageID:null})
		var now = new Date().getTime();
		var data = {when:now,playerID:ewtStore.player.id,comment:value,messageID:messageID}
		ewtStore.firebaseDB.collection("comments").add(data);
		var pic = await ewtStore.getOneDoc("pics",messageID);
		var cc = pic.commentCount;
		if (!cc) cc = 0;
		cc += 1;
		await ewtStore.updateOneDoc("pics",messageID,{commentCount:cc});
		if (ref.current) ref.current.value = "";
		// ref = that.state.commentsRef;
		// setTimeout(function() {ref.current.scrollTop = 0;},100);
	}
	commentKeyUp(e) {
		var len = e.target.value.length;
		this.setState({validComment:len>2,commentBeingEntered:e.target.value});
	}

	async likeMessage(message) {
		if (message.likedByCurrentUser) return;
		if (ewtStore.fanMode) return;
		var pic = await ewtStore.getOneDoc("pics",message.id);
		var likeCount = pic.likeCount;
		if (!likeCount) likeCount = 0;
		likeCount += 1;
		ewtStore.updateOneDoc("pics",message.id,{"likeCount":likeCount});
		var like = {playerID:ewtStore.player.id,messageID:message.id,when:new Date(),tournamentID:ewtStore.tournament.id}
		ewtStore.addOneDoc("likes",like);
		message.likedByCurrentUser = true;
	}

	async unLikeMessage(message) {
		//todo	
	}

	showComments(message) {
		var that = this;
		ewtStore.firebaseDB.collection("comments").where("messageID","==",message.id).onSnapshot(function(snapshot) {
			var mid = message.id;
			var messageComments = that.state.messageComments[mid];
			if (!messageComments) messageComments = [];
			snapshot.docChanges().forEach(function(change) {
				var comment = change.doc.data();
				comment.id = change.doc.id;
				//console.log(change.type);
				if (change.type === "removed") {
					if (messageComments) {
						var foundIndex = -1;
						for (var i=0;i<messageComments.length;i++) {
							if (messageComments[i].id === comment.id) {
								foundIndex = i;
								break;
							}
						}
						if (foundIndex > -1) {
							messageComments.splice(foundIndex,1);
							that.setState(Object.assign(that.state.messageComments,{[mid]:messageComments}))
						}
					}
				} else {
					if (change.type === "added") {
						messageComments.push(comment);
					} else { //edited

					}
				}
			});
			messageComments = messageComments.sort((function (a,b) {
				return (b.when - a.when);
			}))
			if (!ewtStore.fanMode) {
				ewtStore.firebaseDB.collection("likes").where("messageID","==",message.id).where("playerID","==",ewtStore.player.id).get().then((snapshot) => {
					if (snapshot.size > 0) {
						message.likedByCurrentUser = true;
					}
				})
				}
			that.setState({messageComments:Object.assign(that.state.messageComments,{[mid]:messageComments})});
		});
	}

	showAllComments(messageID) {
		var allComments = this.state.allComments;
		allComments[messageID] = true;
		this.setState({allComments:allComments});
	}
	stopComment() {
		ewtStore.usingKeyboard = null;
		this.setState({addCommentMessageID:null});
	}
	deleteComment(comment) {
		if (ewtStore.fanMode) return;
		if (comment.playerID !== ewtStore.player.id  && !ewtStore.adminUser) return;

		confirmAlert({
			title: 'Confirm Delete',
			message: 'Are you sure you are ready to delete this?',
			buttons: [
			  {
				label: 'Yes',
				onClick: () => {
					ewtStore.deleteOneDoc("comments",comment.id);
				}
			  },
			  {
				label: 'No',
			  }
			]
		  })

	}
	editComment(comment) {
		if (ewtStore.fanMode) return;
		if (comment.playerID !== ewtStore.player.id  && !ewtStore.adminUser) return;
		var newComment = prompt("Edit Comment",comment.comment);
		if (!newComment) return;
		comment.comment = newComment;
		ewtStore.updateOneDoc("comments",comment.id,comment);
	}

	getChirp() {
		var chirp = prompt("What's so interesting?");
		if (!chirp) return;
		if (chirp.length < 3) {
			alert("Not enough to say");
			return;
		}
	  var tm = new Date().getTime();
	  var data = {when:tm,playerID:ewtStore.player.id,deleted:false,changed:new Date().getTime(),tournamentID:ewtStore.tournament.id,caption:chirp}
	  ewtStore.firebaseDB.collection("pics").add(data);
	}
  
	  sleep(milliseconds) {
		  const date = Date.now();
		  let currentDate = null;
		  do {
			  currentDate = Date.now();
		  } while (currentDate - date < milliseconds);
	  }  

  getMessageDiv(message) {
	var messagePlayer = ewtStore.players[message.playerID];
	var commentsToShow = this.state.messageComments[message.id];
	var visibleComments = null;
	var moreComments = false;
	if (commentsToShow) {
		var count = 3;
		if (this.state.allComments[message.id]) count = 999;
		visibleComments = commentsToShow.slice(0,count);
		moreComments = (visibleComments.length < commentsToShow.length);
	}

	return 	<div key={message.id}  className="flexColumn" style={{userSelect:'none',marginBottom:5,width:'100%',border:'1px solid lightgray',borderRadius:5,padding:5}} ref={message.id===this.state.firstNewMessageID?this.state.firstNewMessageRef:null}>
					<div className="flexRow" style={{width:'95%'}}>
						<div style={circle} onClick={() => ewtStore.showPlayerCard = ewtStore.players[message.playerID]}>
							<img src={golfball} alt='' style={{height:38,width:38}}></img>
							{messagePlayer && <span style={{position:'absolute',top:'51%',left:'50%',transform:'translate(-50%, -50%)'}}>&nbsp;{messagePlayer.initials}</span>}
						</div>
						<div className="flexRow" style={{marginTop:5}}>
							&nbsp;{messagePlayer.nickname}
							&nbsp;{moment(message.when).from(this.state.now)}
						</div>
						<div className="flexGrow"></div>
						{false && <DeleteOutlined onClick={() => this.deleteMessage(message)} className="ewtIcon" style={{marginTop:5,marginRight:5}}></DeleteOutlined>}
					</div>
					{message.caption && <div className="flexRow" style={{marginLeft:40}} onDoubleClick={() => this.deleteMessage(message)}>
						<span style={{fontSize:18}}>&nbsp;{message.caption}</span>
					</div>}
				<hr style={{width:'100%',color:'lightgray'}}></hr>
				{message.url && <div className="flexRow flexGrow" style={{width:'100%'}}>
					<div className="flexColumn">
						<div style={{marginRight:10,marginLeft:5,fontSize:20,border:this.state.mode==="chats"?'1px solid lightgray':'',borderRadius:5,padding:5}} onDoubleClick={() => this.likeMessage(message)}>
								<img draggable={false} src={message.url} ref={message.ref} alt={''} style={{pointerEvents:'none',cursor:'pointer',objectFit:'contain',maxWidth:'100%'}}></img>
						</div>		
					</div>
				</div>}
				<div className="flexRow" style={{marginLeft:10,marginRight:10,marginTop:5}}>
					{!message.likedByCurrentUser && <FireOutlined className="ewtIcon" onClick={() => this.likeMessage(message)} style={{fontSize:22,scolor:message.likeCount>=5?'red':'black'}}></FireOutlined>}
					{message.likedByCurrentUser && <FireFilled className="ewtIcon" onClick={() => this.unLikeMessage(message)} style={{fontSize:22,scolor:message.likeCount>=5?'red':'black'}}></FireFilled>}
					&nbsp;{message.likeCount}
					<div className="flexGrow"></div>
					{!this.state.addCommentMessageID && <span onClick={() => this.setState({addCommentMessageID:message.id,validComment:false})} style={{width:125}}>Add a comment...&nbsp;</span>}
					{this.state.addCommentMessageID === message.id && 
						<div className="flexRow">
							<TextArea onKeyUp={this.commentKeyUp} showCount autoFocus autoSize={true} maxLength={161} ref={this.state.addCommentRef} onBlur={() => this.stopComment()} onFocus={() => {ewtStore.usingKeyboard = this.state.addCommentRef;ewtStore.usingKeyboardStart = new moment()}} style={{width:'100%'}}></TextArea>
							&nbsp;&nbsp;
							<SendOutlined style={{visibility:this.state.validComment?'visible':'hidden',color:'blue'}} className="ewtIcon" onMouseDown={(e) => {e.preventDefault();this.addComment(message.id)}}></SendOutlined>
						</div>}
				</div>
				{visibleComments && <div className="flexColumn" style={{marginBottom:5}}>
					{visibleComments.map((comment,index) => {
						return (
								<div className="flexRow" style={{marginLeft:40}} key={index}>
									<span onDoubleClick={() => this.deleteComment(comment)}><b>{ewtStore.players[comment.playerID].nickname}</b></span>&nbsp;
									<span onDoubleClick={() => this.editComment(comment)}>{comment.comment}</span>
								</div>
						)
					})}
						{moreComments && <span style={{marginLeft:40,color:'gray'}} onClick={() => this.showAllComments(message.id)}><i>Show all</i><b>{message.messageCount}</b></span>}
				</div>}
			</div>
  }

	render() {
		if (!ewtStore.initialized) return (<div></div>)
		var sortedMessages = null;
		if (this.state.messages) {
			var messages = Object.keys(this.state.messages).map((key) => this.state.messages[key]);
			sortedMessages = messages.sort(function(a,b) {return b.when - a.when});
			sortedMessages = sortedMessages.map((message) => this.getMessageDiv(message));
		}
		if (!ewtStore.initialized) return (<div></div>)
		return (
			<div style={{justifyContent:'center',width:ewtStore.panelWidth-20,height:ewtStore.panelHeight+35,border:'1px solid ' + ewtStore.color2,marginTop:5,marginRight:5,borderRadius:5,padding:10}}>
				<div style={{height:ewtStore.panelHeight - 30}}>
					<PullToRefresh onRefresh={this.handleRefresh}>
						<InfiniteScroll ref={this.state.infiniteScrollRef} onReachTop={() => this.handleScrollUp()} onScroll={this.handleScroll}>
							<List dataSource={sortedMessages}
								renderItem={item => (
									<List.Item>
										{item}
									</List.Item>
								)}
							/>
						</InfiniteScroll>
					</PullToRefresh>
				</div>
				{!ewtStore.fanMode && <div className="flexRow" style={{width:'100%',padding:5}}>
					<div className="flexRow" style={{width:'100%'}}>
						<CameraOutlined onClick={() => {ewtStore.gallery = true;ewtStore.showView="photo"}} style={{fontSize:30,marginTop:15}}></CameraOutlined>
						<div className="flexGrow"></div>
						<div style={{cursor:'pointer'}} onClick={() => this.getChirp()}><img src={chirp} alt={''} style={{width:50,padding:5,marginLeft:-60}}></img></div>
					</div>
				</div>}
				<Modal
					title={<div style={{fontSize:26}}>
						{ewtStore.showFullPic && ewtStore.players[ewtStore.showFullPic.playerID].nickname}&nbsp;{ewtStore.showFullPic && moment(ewtStore.showFullPic.when).fromNow()}
							</div>}
					visible={ewtStore.showFullPic != null}
					bodyStyle={{margin:0,padding:0,height:ewtStore.panelHeight+150,width:'100%',backgroundColor:'silver'}}
					style={{top:0}}
					closable={true}
					onCancel={() => ewtStore.showFullPic = null}
					width={ewtStore.windowWidth}
					footer={null}
					>
					<div style={{padding:10,textAlign:'center',backgroundColor:'silver',width:'100%'}} className="flexColumn">
							{ewtStore.showFullPic && <img className="fullImage" style={{maxHeight:ewtStore.panelHeight - 90,height:ewtStore.panelHeight-90}} src={ewtStore.showFullPic.url} alt=""></img>}
							{!ewtStore.fanMode && <div className="flexRow">
								<CommentOutlined className="ewtIcon" style={{marginTop:10}}></CommentOutlined>
								<input ref={this.state.commentRef} style={{width:200,marginTop:10,marginLeft:10}} onKeyUp={this.commentKeyUp} maxLength="255"></input>
								<Button style={{marginTop:10,marginLeft:10}} type="primary" shape="square" icon={<SendOutlined />} onClick={() => this.addComment(ewtStore.showFullPic.id)}/>
							</div>}
						{this.loadingComments && <SyncOutlined spin style={{marginTop:5,fontSize:30}}/>}
					</div>
				</Modal>
			</div>
		)
	}
};

export default observer(Chat);