import React from 'react';
import './App.css';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import ewtStore from './EWTStore';
import MainView from './components/MainView';
import 'antd/dist/antd.css';
// import eruda from "eruda";

function App() {
  let config = {  apiKey: "AIzaSyCvvsWfcZ9lhh6W8qx3Xbj-QLW2555nNgo ",                             // Auth / General Use
  authDomain: "edwantedthis.firebaseapp.com",         // Auth with popup/redirect
  databaseURL: "https://edwantedthis.firebaseio.com", // Realtime Database
  storageBucket: "edwantedthis.appspot.com",          // Storage
  messagingSenderId: "123456789",                  // Cloud Messaging
  projectId: "edwantedthis"

}

// const el = document.createElement('div');
// document.body.appendChild(el);

// eruda.init({
//     container: el,
//     tool: ['console', 'elements'],
//     useShadowDom: true,
//     autoScale: true,
//     defaults: {
//         displaySize: 50,
//         transparency: 0.9,
//         theme: 'Monokai Pro'
//     }
// });


if (!firebase.apps.length) {
  firebase.initializeApp(config)
  firebase.firestore()
    .enablePersistence({ synchronizeTabs: true })
    .catch(err => alert(err))
}
  ewtStore.firebaseDB = firebase.firestore();
  ewtStore.firebaseStorage = firebase.storage();
  
  var playerID = ewtStore.getCookieValue("playerID");
  firebase.auth().signInWithEmailAndPassword("mikeupton@gmail.com","ewt2020ewt!").then((user) => {
    if (user) {
      // User is signed in.
      // var isAnonymous = user.isAnonymous;
      // var uid = user.uid;
      if (!window.urlParams["data"]) initialize();
    } else {
      // User is signed out.
      // ...
    }

  }).catch((error) => {
    alert(error);
    if (!window.urlParams["data"]) initialize();
  });

  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    ewtStore.deferredInstallPrompt = e;
  });

  async function initialize() {
    firebase.storage().ref("images").child("golfcourse.jpg").getDownloadURL().then(url => {
		  ewtStore.golfcourseImageURL = url;
    });
		firebase.storage().ref("images").child("coursemap.jpg").getDownloadURL().then(url => {
			ewtStore.courseMapURL = url;
		});   

		firebase.storage().ref("images").child("mapicon.png").getDownloadURL().then(url => {
			ewtStore.mapIconURL = url;
		});   

		firebase.storage().ref("images").child("logo.png").getDownloadURL().then(url => {
			ewtStore.logoURL = url;
		});

    if (ewtStore.getCookieValue("fanMode") === "true") ewtStore.setFanMode(true);
    
    await ewtStore.setOrg("1",null); //would eventually come from a url or account info

    var tournamentID = ewtStore.org.currentTournamentID;

    if (playerID || (ewtStore.fanMode)) {
      if (playerID) await ewtStore.setPlayer(playerID,null);
      //handle invalid player id *TODO*
      ///change to use org.currentTournamentID
      if (tournamentID) {
        ewtStore.setTournament(tournamentID,null).then(() => {
          if (ewtStore.currentRoundID) {
            //handle player not in a group
          } else {
            ewtStore.showView = "SETUP";
            return;
          }
        })
      } else {
        ewtStore.showView = "SETUP";
        return;
      }
    } else {
      ewtStore.showView = "SETUP";
    }
  }
  
// if (!window.urlParams["data"]) {
  return (
        <MainView></MainView>
  );
// } else {
//   return (
//     <Data></Data>
//   )
// }
}

export default App;
