import { Button, Input, Checkbox } from 'antd';
import React from 'react';
import { produce } from 'immer';

export const PlayerDetails = (props) => {

    const [player, _setPlayer] = React.useState({...props.player});
    const setPlayer = (prevState => {
        let tournamentHandicaps = [];
        for (const tournamentID of prevState.tournaments) {
            for (const hcTournamentID of Object.keys(prevState.handicaps)) {
                if (hcTournamentID === tournamentID) tournamentHandicaps[tournamentID] = prevState.handicaps[hcTournamentID];
            }
        }
        const updatedValues = {tournamentHandicaps:tournamentHandicaps}
        _setPlayer({...prevState, ...updatedValues});
      });

    React.useEffect(() => {
        setPlayer(props.player);
    },[props.player]);

    if (!props.player) return (
        <div></div>
    )

    const setProperty = (p,propertyName, value) => {
        const nextPlayer = produce(p, draft => {
            draft[propertyName] = value;
        })
       setPlayer(nextPlayer);
    }

    return (
        <div className="flexColumn" style={{gap:'10px'}}>
            <Button onClick={() => props.savePlayer(player)}>Save Player</Button>
            &nbsp;Player ID&nbsp;{player.id}            &nbsp;Player Code&nbsp;{player.code}

            <Input addonBefore="Last Name" value={player.last_name} onChange={(e) => setProperty(player,"last_name", e.target.value)}></Input>
            <Input addonBefore="First Name" value={player.first_name} onChange={(e) => setProperty(player,"first_name", e.target.value)}></Input>
            <Input addonBefore="Nickname" value={player.nickname} onChange={(e) => setProperty(player,"nickname", e.target.value)}></Input>
            <div className="flexRow">
            <Checkbox checked={player.active} onChange={(e) => setProperty(player,"active", e.target.checked)}>Active</Checkbox>
            <Checkbox checked={player.admin} onChange={(e) => setProperty(player,"admin", e.target.checked)}>Administrator</Checkbox>
            </div>
            <div className="flexColumn">
            Tournaments
            <div className="flexRow">
                <div style={{width:50}}>Year</div><div style={{width:30}}>Handicap</div>
                </div>
            {player.tournamentHandicaps && player.tournaments.map((tournamentID) => 
                <div key={tournamentID} className="flexRow">
                    <div style={{width:50}}>
                    {tournamentID}
                    </div>
                    <div>
                        {player.tournamentHandicaps[tournamentID]}
                        </div>
                        </div>
            )}
            </div>
        </div>
    )
}
