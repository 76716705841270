import React from 'react';
import { observer } from "mobx-react"
import ewtStore from '../EWTStore';
import { Button } from 'antd';
import {CameraFilled,SendOutlined} from '@ant-design/icons';
import CameraPhoto from 'jslib-html5-camera-photo';
import { Input } from 'antd';

const { TextArea } = Input;

const PlayerPhoto = observer(class PlayerPhoto extends React.Component {

	constructor(props) {
		super(props);
		this.state = {dataUri:null,camRef:React.createRef(),took:false,imgSrc:null,numcams:0,facingMode:'user',caption:'',approved:false};
		this.takePhoto = this.takePhoto.bind(this);
		this.setNumberOfCameras = this.setNumberOfCameras.bind(this);
		this.swapCams = this.swapCams.bind(this);
		this.loadFile = this.loadFile.bind(this);
		this.readFile = this.readFile.bind(this);
		this.captionChange = this.captionChange.bind(this);
		this.approvePhoto = this.approvePhoto.bind(this);
		this.stopCamera = this.stopCamera.bind(this);
		this.cameraPhoto = null;
		this.videoRef = React.createRef();
	}
	
	componentDidMount() {
		this.cameraPhoto = new CameraPhoto(this.videoRef.current);	
		this.startCamera();	
	}

	componentWillUnmount() {
		this.stopCamera();
	}

	stopCamera() {
		this.cameraPhoto.stopCamera()
		.then(() => {
		// console.log('Camera stoped!');
		})
		.catch((error) => {
		// console.log('No camera to stop!:', error);
		});

	}

	setNumberOfCameras(num) {
		this.setState({numcams:num});
	}

	swapCams() {
		var that = this;
		this.setState({facingMode:this.state.facingMode==="user"?"environment":"user"},() => that.startCamera())
	}

	approvePhoto() {
		if (this.props.gallery) {
			this.setState({approved:true});
		} else {
			this.savePhoto();
		}
	}

	readFile(file) {
		var reader = new FileReader();
		var that = this;
		reader.onloadend = function () {			
			that.setState({imgSrc:reader.result,took:true},() => {
				//that.savePhoto();
			})
		}

		reader.onerror = function () {
			alert('There was an error reading the file!');
		}

		reader.readAsDataURL(file);
		this.setState({approved:true,took:true})
	}

	loadFile(e) {
		this.stopCamera();
		var file = e.target.files[0];
		e.target.value = '';
		if (file) {
			if (/^image\//i.test(file.type)) {
				this.readFile(file);
			} else {
				alert('Not a valid image!');
			}
		} else {
			this.startCamera();
		}
	}

	savePhoto() {
		var pathName = "";
		var data = null;
		if (this.props.gallery) {
			if (!this.state.caption || this.state.caption.length < 2) {
				alert("Enter a valid caption");
				return;
			}
			var tm = new Date().getTime();
			pathName = "/gallery/" + tm + ".png";				
			data = {name:tm + "",when:tm,playerID:ewtStore.player.id,deleted:false,changed:new Date().getTime(),tournamentID:ewtStore.tournament.id,caption:this.state.caption}
		} else {
			pathName = "/playerpics/t" + ewtStore.tournament.id + "/pp" + ewtStore.player.id + ".png";
		}
		ewtStore.firebaseStorage.ref("images").child(pathName).putString(this.state.imgSrc, 'data_url').then(() => {
			if (data) ewtStore.firebaseDB.collection("pics").add(data);
		});
		ewtStore.showView = "MV";
	  }

	  captionChange(e) {
		this.setState({caption:e.target.value});
	  }


	  startCamera () {
		this.setState({took:false,approved:false});
		this.cameraPhoto.startCamera(this.state.facingMode,{})
		  .then(() => {
			//console.log('camera is started !');
		  })
		  .catch((error) => {
			console.error('Camera not started!', error);
		  });
	  }
	
	  takePhoto () {
		const config = {
		  sizeFactor: 1
		};
	
		let dataUri = this.cameraPhoto.getDataUri(config);
		this.setState({imgSrc:dataUri,took:true,approved:false});
		this.stopCamera();
	  }

	render() {
		return (
			<div style={{overflowY:'auto',width:ewtStore.panelWidth-25}}>
				<div style={{display:this.state.took?'none':'',backgroundColor:'black'}} className='flexColumn'>
					<video 
						ref={this.videoRef}
						autoPlay={true}
					/>
					<span onClick={() => this.takePhoto()} style={{marginTop:10,marginBottom:10,backgroundColor:'white',borderRadius:'50%',width:30,height:30,display:'inline-block',alignSelf:'center'}}></span>
					<div style={{zIndex:999,position:'fixed',bottom:20}} className="flexRow">
						{(this.state.numcams > 0) && <CameraFilled onClick={()=> this.takePhoto()} style={{marginLeft:100,zIndex:2,fontSize:50,marginTop:-10,cursor:'pointer'}}></CameraFilled>}
						<Button type="primary" onClick={() => this.swapCams()}>Swap Cameras</Button>&nbsp;
						{!ewtStore.adminUser.fanMode && <label onClick={() => this.stopCamera()} type="primary" style={{backgroundColor:'dodgerblue',color:'white',height:32,padding:5,paddingLeft:10,paddingRight:10}}>Select Existing
                        	<input ref={this.state.inputRef} type="file" accept="image/*" style={{display:'none'}} onChange={this.loadFile}></input>
                    	</label>}
						&nbsp;&nbsp;&nbsp;&nbsp;<Button onClick={() => ewtStore.showView = "MV"} type="primary">Cancel</Button>
					</div>
				</div>
				{(this.state.took && !this.state.approved) && <>
					<div style={{marginTop:10}}>
						<img src={this.state.imgSrc} alt="" style={{width:ewtStore.panelWidth}}></img>
							<div className="flexRow" style={{marginTop:10,marginLeft:10}}>
								<Button onClick={() => this.startCamera()} type="primary">Redo</Button>
								<Button onClick={() => this.approvePhoto()} type="primary" style={{marginLeft:10}}>Keeper</Button>
								<Button onClick={() => ewtStore.showView = "MV"} type="primary" style={{marginLeft:40}}>Cancel</Button>
							</div>
					</div>
				</>}
				{(this.state.approved) && <>
					<div>
						<img src={this.state.imgSrc} alt="" style={{width:ewtStore.panelWidth}}></img>
						<div className="flexColumn" style={{marginTop:10,marginLeft:10}}>
							<b>Caption</b>
							<div className="flexRow" style={{marginTop:-10,width:'100%'}}>
								<TextArea maxLength={161} className="flexGrow" showCount autoSize={{ minRows: 1, maxRows: 4 }} onChange={(e) => this.captionChange(e)} autoFocus style={{marginTop:-15}}></TextArea>
								<SendOutlined style={{color:'blue',fontSize:26,marginTop:-10,marginLeft:5,marginRight:5}} onClick={() => this.savePhoto()} type="primary">Post</SendOutlined>
							</div>
							<Button onClick={() => this.startCamera()} type="primary" style={{position:'absolute',top:70,left:20,width:80}}>Redo</Button>
						</div>
					</div>
				</>}
			</div>
	  	);
	}
});


export default PlayerPhoto;