import { Button, Input, Checkbox, Modal } from 'antd';
import React from 'react';
import ewtStore from '../EWTStore';
import { EditFilled } from '@ant-design/icons';
import { DataGroupEdit } from './DataGroupEdit';
import { DataRoundEdit } from './DataRoundEdit';

export const DataTournaments = (props) => {
    const [tournaments, setTournaments] = React.useState(null);
    const [selectedTournamentIndex, _setSelectedTournamentIndex] = React.useState(-1);
    const [rounds, setRounds] = React.useState(null);
    const [tournamentRounds, setTournamentRounds] = React.useState(null);
    const [selectedRoundIndex, setSelectedRoundIndex] = React.useState(-1);
    const [showGroupEdit, setShowGroupEdit] = React.useState(false);
    const [selectedGroup, setSelectedGroup] = React.useState(null);
    const [showRoundEdit, setShowRoundEdit] = React.useState(false);
    const [selectedRound, setSelectedRound] = React.useState(null);

    const setSelectedTournamentIndex = React.useCallback(async (index) => {
        _setSelectedTournamentIndex(index);
        let trounds = rounds.filter((round) => round.tournamentID === tournaments[index].id);
        trounds = trounds.sort(function (a, b) { return new Date(b.date).getTime() - new Date(a.date).getTime() })
        setTournamentRounds(trounds, setSelectedRoundIndex(0));
        //add players to the group
        //edit a round
        //create foursomes
        //delete foursomes
        //save foursomes
        //save a round
        //set handicaps for the players in the tournament
        //save changes
        //add a tournament
    }, [tournaments, rounds]);


    const getTournaments = async () => {
        const snapshotr = await ewtStore.firebaseDB
            .collection("rounds").get();
        setRounds(snapshotr.docs.map((doc) => { const x = doc.data(); x.id = doc.id; return x }));
        const snapshot = await ewtStore.firebaseDB.collection('tournaments').orderBy("name", "desc").get();
        setTournaments(snapshot.docs.map((doc) => { const x = doc.data(); x.id = doc.id; return x }));
    }

    React.useEffect(() => {
        if (selectedTournamentIndex === -1 && tournaments && rounds) setSelectedTournamentIndex(0);
    }, [tournaments, rounds, setSelectedTournamentIndex, selectedTournamentIndex])

    React.useEffect(() => {
        getTournaments();
    }, []);

    let groups = null;
    if (selectedRoundIndex !== -1 && tournamentRounds && tournamentRounds.length > 0) {
        const round = tournamentRounds[selectedRoundIndex];
        groups = Object.keys(round.groups).map((groupID, index) => { const x = round.groups[groupID]; x.id = groupID; return x; });
        groups = groups.sort((a, b) => a.groupNumber - b.groupNumber);
        groups.forEach((group) => {
            const groupPlayerIDs = Object.keys(round.players).filter((playerID) => round.players[playerID].groupID === group.id);
            group.groupPlayers = groupPlayerIDs.map((playerID) => { return props.players.filter((player) => player.id === playerID)[0] });
            const x = 1;
        })
    }

    return (
        <div className="flexColumn" style={{ gap: '5px', marginTop: 10 }}>
            <div className="flexRow">
                <div className="flexColumn" style={{ width: 155, flexGrow: 0, gap: 5 }}>
                    <h3>Tournaments</h3>
                    <div className="flexRow">
                        <div style={{ width: 75 }}>Name</div>
                        <div style={{ width: 75 }}>Fan Code</div>
                    </div>
                    {tournaments && tournaments.map((tournament, index) =>
                        <div className="flexRow" key={index} style={{ border: '1px solid lightgray', padding: 5, width: 155, cursor: 'pointer', backgroundColor: index === selectedTournamentIndex ? 'lightblue' : '' }}
                            onClick={() => setSelectedTournamentIndex(index)} >
                            <div style={{ width: 75 }}>{tournament.name}</div>
                            <div style={{ width: 75 }}>{tournament.fan_code}</div>
                        </div>
                    )}
                </div>
                {selectedTournamentIndex !== -1 && tournamentRounds && <div style={{ marginLeft: 5 }}>
                    <div className="flexColumn" style={{ gap: 5 }}>
                        <div className="flexRow">
                        <div className="flexGrow"><h3>Rounds</h3></div>
                        <Button onClick={() => {setSelectedRound({orgID:"1",courseID:"1",finalized:false,fixedPairings:false,open:false,tournamentID:tournaments[selectedTournamentIndex].id,groups:{},players:{}},setShowRoundEdit(true))}}>Add Round</Button>
                        </div>
                        <div className="flexRow">
                            <div style={{ width: 70 }}>Date</div>
                            <div style={{ width: 70 }}>Name</div>
                            <div style={{ width: 70 }}>Tees</div>
                            <div style={{ width: 50 }}>Practice</div>

                        </div>
                        {tournamentRounds.map((tr, index) =>
                            <div key={index} className="flexRow" style={{ cursor: 'pointer', border: '1px solid lightgray', padding: 5, backgroundColor: index === selectedRoundIndex ? 'lightblue' : '' }}
                                onClick={(() => setSelectedRoundIndex(index))}>
                                <div style={{ width: 70 }}>{tr.date}</div>
                                <div style={{ width: 70 }}>{tr.name}</div>
                                <div style={{ width: 70 }}>{tr.tees}</div>
                                <div style={{ width: 50 }}>{tr.practice ? 'Yes' : ''}</div>
                                <div>
                                    <EditFilled onClick={() => { setSelectedRound(tr); setShowRoundEdit(true) }} style={{ fontSize: 18, padding: 2 }} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>}
                {groups && <div className="flexColumn" style={{ marginLeft: 10, gap: 5, width: 200, flexGrow: 0 }}>
                    {groups.map((group, index) =>
                        <div key={index} style={{ padding: 5, border: '1px solid lightgray' }}>
                            <div className="flexRow" style={{ width: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <h4>Group&nbsp;{group.groupNumber}&nbsp;{group.teeTime}</h4>
                                </div>
                                <div style={{ cursor: 'pointer' }}>
                                    <EditFilled onClick={() => { setSelectedGroup(group); setShowGroupEdit(true) }} style={{ fontSize: 18, padding: 2 }} />
                                </div>
                            </div>

                            {group.groupPlayers && <div className="flexColumn">
                                {group.groupPlayers.map((groupPlayer, index) => {
                                    return (
                                        <div key={index} className="flexRow">
                                            <div>{groupPlayer.first_name.substr(0, 1)}.&nbsp;</div>
                                            <div>{groupPlayer.last_name}</div>
                                            <div>&nbsp;&nbsp;(
                                                {groupPlayer && groupPlayer.handicaps && groupPlayer.handicaps[tournaments[selectedTournamentIndex].id] && groupPlayer.handicaps[tournaments[selectedTournamentIndex].id]}
                                                )</div>
                                        </div>
                                    )
                                }
                                )}
                            </div>}
                        </div>
                    )}
                </div>}
            </div>
            <DataRoundEdit open={showRoundEdit} hideModal={() => setShowRoundEdit(false)} round={selectedRound}></DataRoundEdit>
            <DataGroupEdit open={showGroupEdit} hideModal={() => setShowGroupEdit(false)} group={selectedGroup} players={props.players}></DataGroupEdit>
        </div>
    )
}
