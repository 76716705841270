import { Input, Modal, Checkbox } from 'antd';
import React from 'react';
import ewtStore from '../EWTStore';

export const DataRoundEdit = (props) => {
    const [round, setRound] = React.useState(null);
    const [originalRound, setOriginalRound] = React.useState(null);

    React.useEffect(() => {
        if (props.round) {
            let round = JSON.parse(JSON.stringify(props.round));
            setRound(round);
            setOriginalRound(round);
        }
    }, [props.round])

    const save = async () => {
        let roundToSave = JSON.parse(JSON.stringify(round));
        if (roundToSave.groups) {
            Object.keys(roundToSave.groups).forEach((groupID) => {
                delete roundToSave.groups[groupID].groupPlayers;
            })
        }
        if (roundToSave.id) {
            ewtStore.updateOneDoc("rounds",round.id,roundToSave);
        } else { //adding a round
            ewtStore.addOneDoc("rounds",roundToSave);
        }
        //add round if needed
        //else save round
        props.hideModal();
    }

    return (
        <div>
            <Modal
                title="Edit Round"
                visible={props.open}
                onOk={save}
                onCancel={props.hideModal}
                okText="Save"
                cancelText="Cancel"
            >
                {round && <div>
                    <Input addonBefore="Name" value={round.name} onChange={(e) => setRound({
                        ...round,
                        name: e.target.value
                    })} />
                    <Input addonBefore="Date" value={round.date} onChange={(e) => setRound({
                        ...round,
                        date: e.target.value
                    })} />
                    <Input addonBefore="Round #" value={round.roundNumber} onChange={(e) => setRound({
                        ...round,
                        roundNumber: e.target.value
                    })} />
                    <Input addonBefore="Short Name" value={round.shortName} onChange={(e) => setRound({
                        ...round,
                        shortName: e.target.value
                    })} />
                    <div className="flexColumn">
                        <div><Checkbox checked={round.open} onChange={(e) => setRound({ ...round, open: e.target.checked })}>Open for Scoring</Checkbox></div>
                        <div><Checkbox checked={round.fixedPairings} onChange={(e) => setRound({ ...round, fixedPairings: e.target.checked })}>Fixed Pairings</Checkbox></div>
                        <div><Checkbox checked={round.finalized} onChange={(e) => setRound({ ...round, finalized: e.target.checked })}>Finalized</Checkbox></div>

                    </div>
                </div>}
            </Modal>
        </div>
    )
}
