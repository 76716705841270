import React from 'react';
import ewtStore from '../EWTStore';
import { observer } from "mobx-react"
import Setup from './Setup';
import Leaderboard from './Leaderboard';
import PlayerCard from './PlayerCard';
import CourseCard from './CourseCard';
import Scoring from './Scoring';
import Chat from './Chat';
import PlayerPhoto from './PlayerPhoto';
import {Menu,Dropdown,Modal} from 'antd';
import '../App.css';
import {CommentOutlined,CompassFilled,CameraOutlined,TrophyOutlined,EditOutlined,MenuOutlined} from '@ant-design/icons';
import RoundSelector from './RoundSelector';
import RoundMaint from './RoundMaint';
import CorrectScore from './CorrectScore';
import Pairings from './Pairings';
import moment from 'moment';
import Data from './Data';

const MainView = observer(class MainView extends React.Component {
    state = {
		windowHeight: window.innerHeight,courseCardRoundID:''
    };

    componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
		ewtStore.showView = "MV"; //data
		this.setState({current:ewtStore.currentView});
		this.roundChanged = this.roundChanged.bind(this);
		if (typeof window.history.pushState === "function") {
			window.history.pushState("jibberish", null, null);
			window.window.onpopstate = function () {
				if (ewtStore.showPlayerCard) ewtStore.showPlayerCard = false;
				if (ewtStore.showCourseCard) ewtStore.showCourseCard = false;
				if (ewtStore.showCourseMap) ewtStore.showCourseMap = false;
				if (ewtStore.correctScore) ewtStore.correctScore = null;
				if (ewtStore.showFullPic) ewtStore.showFullPic = null;
				if (ewtStore.showHole) ewtStore.showHole = null;
				if (ewtStore.showPairings) ewtStore.showPairings = false;
				window.history.pushState('newjibberish', null, null);
				// Handle the back (or forward) buttons here
				// Will NOT handle refresh, use onbeforeunload for this.
			};
		}
		else {
			var ignoreHashChange = true;
			window.onhashchange = function () {
				if (!ignoreHashChange) {
					ignoreHashChange = true;
					console.log("backish")
					window.location.hash = Math.random();
					// Detect and redirect change here
					// Works in older FF and IE9
					// * it does mess with your hash symbol (anchor?) pound sign
					// delimiter on the end of the URL
				}
				else {
					ignoreHashChange = false;   
				}
			};
		}
	}

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = (e) => {
		if (ewtStore.usingKeyboard) {
			if (new moment().diff(ewtStore.usingKeyboardStart) > 200) {
				if (ewtStore.usingKeyboard.current) ewtStore.usingKeyboard.current.blur();
			}
			return;
		}
		this.setState({windowHeight: window.innerHeight})
		ewtStore.windowHeight = window.innerHeight;
		ewtStore.windowWidth = window.innerWidth;
		ewtStore.panelHeight = window.innerHeight - 170;
		ewtStore.panelWidth = window.innerWidth<400?window.innerWidth:400;
    };

	handleClick = e => {
		ewtStore.currentView = e.key;
		this.setState({current:e.key})
	  };

	  roundChanged(roundID) {
		this.setState({courseCardRoundID:roundID});
	}

	render() {
		const minHeight = this.state.windowHeight;
		const menu = (
			<Menu style={{fontSize:26}}>
				{!ewtStore.fanMode && <Menu.Item key="photo" style={{fontSize:26,padding:10}} onClick={() => {ewtStore.gallery=false;ewtStore.showView="photo"}}>Player Photo</Menu.Item>}
				{ewtStore.initialized && ewtStore.rounds && <Menu.Item key="pairings" onClick={() => ewtStore.showPairings = true} style={{fontSize:26,padding:10}}>Pairings</Menu.Item>}
				{ewtStore.player && ewtStore.player.admin && ewtStore.tournament && ewtStore.rounds && <Menu.Item key="editrounds" onClick={() => ewtStore.editRounds = true} style={{fontSize:26,padding:10}}>Edit Rounds</Menu.Item>}
				{false && <Menu.Item key="chatoff" style={{fontSize:26,padding:10}}>Chat Notifications Off</Menu.Item>}
				{false && <Menu.Item key="chaton" style={{fontSize:26,padding:10}}>Chat Notifications On</Menu.Item>}
				{false && <Menu.Item key="cadmin" style={{fontSize:26,padding:10}}>Admin</Menu.Item>}
				<Menu.Item key="player" onClick={() => ewtStore.showView="SETUP"} style={{fontSize:26,padding:10}}>Change Player</Menu.Item>
				{!ewtStore.fanMode && <Menu.Item key="tournament" onClick={() => {ewtStore.selectTournament=true}} style={{fontSize:26,padding:10}}>Select Tournament</Menu.Item>}
				{ewtStore.player && ewtStore.player.admin && ewtStore.tournament && ewtStore.rounds && <Menu.Item key="datasetup" onClick={() => {ewtStore.showView="data"}} style={{fontSize:26,padding:10}}>Data Setup</Menu.Item>}
			</Menu>
			);
		  
		return (
		<div className="flexColumn" style={{ height: minHeight - 10,width:window.innerWidth - 10,padding:5,margin:5,border:'1px solid gray',borderRadius:5}}>
				<div className="flexColumn flexGrow">
					<div className="flexRow" style={{width:'100%',backgroundColor:'white',fontSize:16,color:ewtStore.color1,padding:5}}>
						<div className="flexGrow noselect flexRow" style={{height:30,width:'100%',fontSize:26,textAlign:'center',cursor:'pointer'}}>
							<div className="flexRow" style={{marginTop:4,marginRight:5,fontSize:30,color:'black'}} onClick={() => {if (ewtStore.course) ewtStore.showCourseMap = true}}><CompassFilled/></div>
							<div className="flexRow" onClick={() => {if (ewtStore.initialized) ewtStore.showCourseCard = true}}>
								<img src={ewtStore.logoURL} alt="" style={{height:50,marginTop:-7}}/>{ewtStore.tournament && <b>{ewtStore.tournament.name}</b>}
								<span>&nbsp;&nbsp;{ewtStore.initialized && ewtStore.rounds[ewtStore.currentRoundID].name}</span>
								{!ewtStore.org && <b>EWT</b>}
							</div>
						</div>
						<div style={{margin:0,padding:0}}>
							<Dropdown overlay={menu} style={{cursor:'pointer'}} trigger={['click']}>
								<MenuOutlined style={{padding:5,fontSize:26}}/>
							  </Dropdown>
						</div>
					</div>
					{ewtStore.showView === "MV" &&
					<> 
						<div className="flexGrow flexColumn" style={{color:'#10172d'}}>
							{ewtStore.initialized && <div className="flexGrow flexColumn" style={{position:ewtStore.currentView === 'chats'?'':'absolute',left:-10000}}>
								<Chat mode="pics"></Chat>
							</div>}
							<div className="flexGrow flexColumn" style={{display:ewtStore.currentView === 'leaderboard'?'':'none'}}>
								<Leaderboard></Leaderboard>
							</div>
							{!ewtStore.fanMode && <div className="flexGrow flexColumn" style={{display:ewtStore.currentView === 'scoring'?'':'none'}}>
								{ewtStore.initialized && <Scoring></Scoring>}
							</div>}
						</div>
						<div style={{padding:5,textAlign:'center',marginTop:5}} className="noselect flexRow">
							<div className={`menuDiv ${ewtStore.currentView==='leaderboard' ? "activeMenu" : ""}`} onClick={() => ewtStore.currentView = 'leaderboard'}><TrophyOutlined style={{width:30}}/>Cup</div>
							{!ewtStore.fanMode && <div className={`menuDiv ${ewtStore.currentView==='scoring' ? "activeMenu" : ""}`} onClick={() => ewtStore.currentView = 'scoring'}><EditOutlined style={{width:30}}/>Score</div>}
							<div className={`menuDiv ${ewtStore.currentView==='chats' ? "activeMenu" : ""}`} onClick={() => {ewtStore.unread["chats"] = false;ewtStore.currentView = 'chats'}}>
								<CommentOutlined style={{width:30,color:ewtStore.unread["chats"]?'red':''}} />ED Talk
							</div>
							{false && <div className={`menuDiv ${ewtStore.currentView==='pics' ? "activeMenu" : ""}`} onClick={() => {ewtStore.unread["pics"] = false;ewtStore.currentView = 'pics'}}>
								<CameraOutlined  style={{width:30,color:ewtStore.unread["pics"]?'red':''}}/>Pics
							</div>}
						</div>
					</>
					}
					{ewtStore.showView === "SETUP" && <Setup></Setup>}
					{ewtStore.showView === "photo" && <PlayerPhoto gallery={ewtStore.gallery}></PlayerPhoto>}
					{ewtStore.showView === "data" && <Data></Data>}
				</div>
				{ewtStore.correctScore && <CorrectScore></CorrectScore>}
				{ewtStore.showPlayerCard && <PlayerCard player={ewtStore.showPlayerCard}></PlayerCard>}
				{ewtStore.editRounds && <RoundMaint></RoundMaint>}
				<Modal
					title={<div style={{width:'100%',fontSize:26}}>
							<div className="flexRow" style={{marginBottom:20}}>
								{ewtStore.course && ewtStore.course.name}&nbsp;&nbsp;
							</div>
							<RoundSelector roundID={this.state.courseCardRoundID} handleEvent={this.roundChanged}></RoundSelector></div>}
					visible={ewtStore.showCourseCard}
					bodyStyle={{margin:0,padding:0,height:ewtStore.panelHeight+70}}
					style={{top:0}}
					closable={true}
					onCancel={() => ewtStore.showCourseCard = false}
					width={ewtStore.windowWidth}
					footer={null}
					>
					<CourseCard roundID={this.state.courseCardRoundID}></CourseCard>
				</Modal>
				<Modal
					title={<div style={{width:300,fontSize:26}}>Select Tournament</div>}
					visible={ewtStore.selectTournament}
					bodyStyle={{margin:0,padding:0,height:ewtStore.panelHeight+70}}
					style={{top:0}}
					closable={true}
					onCancel={() => ewtStore.selectTournament = false}
					width={300}
					footer={null}
					>
					<div style={{marginLeft:20,marginTop:20}}>
						{ewtStore.initialized && ewtStore.player && ewtStore.player.fullTournaments && ewtStore.player.fullTournaments.map((tournament,index) => {
							return (<div key={index} style={{cursor:'pointer',fontSize:26,marginBottom:5}} onClick={() => {ewtStore.setTournament(tournament.id,null,true);ewtStore.selectTournament=false}}>{tournament.name}</div>)
						})}
					</div>
				</Modal>
				<Modal
					title={<span style={{fontSize:30}}>Hole Map # {ewtStore.showHole?ewtStore.showHole:''}</span>}
					visible={ewtStore.showHole != null}
					onCancel={() => {ewtStore.holeMapURL=null;ewtStore.showHole = null}}
					onOk={()=> ewtStore.showHole = null}
					bodyStyle={{margin:0,padding:0,height:window.innerHeight - 115,zIndex:999}}
					style={{top:0,zIndex:99}}
					closable={false}
					>
						<img className="fullImage" src={ewtStore.holeMapURL} style={{maxHeight:ewtStore.panelHeight+100,height:ewtStore.panelHeight+100}} alt=""></img>
				</Modal>
				<Modal
					title={<span style={{fontSize:30}}>Course Map {ewtStore.course && ewtStore.course.name}</span>}
					visible={ewtStore.showCourseMap}
					onCancel={() => ewtStore.showCourseMap = null}
					onOk={()=> ewtStore.showCourseMap = null}
					bodyStyle={{margin:0,padding:0,height:window.innerHeight - 115,zIndex:999}}
					style={{top:0,zIndex:99}}
					closable={false}
					>
						<img className="fullImage" src={ewtStore.courseMapURL} style={{maxHeight:ewtStore.panelHeight+100,height:ewtStore.panelHeight+100}} alt="Loading"></img>
				</Modal>
				{ewtStore.showPairings && <Modal
					title={<span style={{fontSize:30}}>Pairings</span>}
					visible={ewtStore.showPairings}
					onCancel={() => ewtStore.showPairings=false}
					cancelButtonProps={{style:{display:'none'}}}
					onOk={()=> ewtStore.showPairings = false}
					bodyStyle={{margin:0,padding:0,height:window.innerHeight - 115,zIndex:999}}
					style={{top:0,zIndex:99}}
					closable={false}
					>
						<Pairings all={true}></Pairings>
				</Modal>}
		</div>
	  );
	}
});


export default MainView;