import { Button, Input, Transfer, Modal } from 'antd';
import React from 'react';
import ewtStore from '../EWTStore';

export const DataGroupEdit = (props) => {
    const [group, setGroup] = React.useState(null);
    const [targetKeys,setTargetKeys] = React.useState(null);
    const [selectedKeys, setSelectedKeys] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [originalGroup,setOriginalGroup] = React.useState(null);

    React.useEffect(() => {
        if (props.group) {
            let group = JSON.parse(JSON.stringify(props.group));
            let tk = [];
            group.groupPlayers.forEach((groupPlayer) => {
                tk.push(groupPlayer.id);
            })
            setTargetKeys(tk);
            setGroup(group);
            setOriginalGroup(group);
        }
    }, [props.group])

    React.useEffect(() => {
        props.players.forEach((player) => {
            player.key = player.id;
        })
        setData(props.players);
    },[props.players])

    const handleChange = (newTargetKeys, direction, moveKeys) => {
        if (newTargetKeys.length > 4) {
            alert("Maximum of 4 players in a group");
            return;
        }
        setTargetKeys(newTargetKeys);
      };
      const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        if (targetKeys.length === 4) {
            alert("Maxium of 4 players in a group");
            return;
        }
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
      };

      const save = async () => {
        //check for players who have been added to the group
        //check for players who have been deleted from the group
        props.hideModal();  
      }

    return (
        <div>
            <Modal
                title="Modal"
                visible={props.open}
                onOk={save}
                onCancel={props.hideModal}
                okText="Save"
                cancelText="Cancel"
            >
                {group && <div>
                    <Input addonBefore="Group #" value={group.groupNumber} onChange={(e) => setGroup({
                        ...group,
                        groupuNumber: e.target.value
                    })} />

                    <Input addonBefore="Tee Time" value={group.teeTime} onChange={(e) => setGroup({
                        ...group,
                        teeTime: e.target.value
                    })} />
                    <Transfer
                        dataSource={data}
                        showSelectAll={false}
                        titles={['All Players', 'In this Group']}
                        targetKeys={targetKeys}
                        selectedKeys={selectedKeys}
                        render={(item) => item.shortName}
                        onChange={handleChange}
                        onSelectChange={handleSelectChange}
                        oneWay
                        style={{ marginBottom: 16 }}
                    />
                </div>}
            </Modal>
        </div>
    )
}
