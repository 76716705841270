import React from 'react';
import ewtStore from '../EWTStore';
import { observer } from "mobx-react"

const Setup = observer(class Setup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {myRef:React.createRef(),
					  view:"player","tournaments":[],tournament:null,player:null}
		this.go = this.go.bind(this);
	}

	go() {
		var ref = this.state.myRef;
		var playerCode = ref.current.value;
		playerCode = playerCode.toUpperCase();
		ewtStore.firebaseDB.collection('players').where("code","==",playerCode).get().then((ss) => {
			if (ss.size === 1) {
				var player = ss.docs[0].data();
				player.id = ss.docs[0].id;
				this.setState({"player":player});
				ewtStore.setFanMode(false);
				ewtStore.setPlayer(player.id,player).then(() => {
					ewtStore.setTournament(ewtStore.org.currentTournamentID);
					ewtStore.showView = "MV";
				})
			} else {
				ewtStore.firebaseDB.collection('tournaments').where("fan_code","==",playerCode).get().then((ss) => {
					if (ss.size === 1) {
						ewtStore.setFanMode(true);
						var tournament = ss.docs[0].data();
						tournament.id = ss.docs[0].id;
						ewtStore.setTournament(tournament.id,tournament);
						ewtStore.showView = "MV";
					} else {
						alert("invalid player code");

					}
				});
			}
		})
	}

	render() {


		return (
		<div style={{marginTop:10,marginLeft:10}} className="flexColumn noselect">
			 <div style={{height:50,backgroundColor:ewtStore.color2,fontSize:32}}>EWT Golf</div>
			{this.state.view === "player" && <div>
				<div style={{fontSize:32}}>
				<b>Enter Player Code&nbsp;</b>
				<input style={{width:150}} autoFocus ref={this.state.myRef} defaultValue=""></input>
				<br></br>
				<div style={{marginLeft:185,marginTop:10}}><input type="button" style={{cursor:'pointer'}} onClick={this.go} value="Go"></input></div>
				</div>
			</div>}
			<div style={{width:'100%',marginTop:5}}>
				{false && <img src={ewtStore.golfcourseImageURL} alt={'none available'} style={{width:window.innerWidth - 20}}></img>}

			</div>
		</div>
	  );
	}
});


export default Setup;