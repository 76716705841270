import React from 'react';
import ewtStore from '../EWTStore';
import { observer } from "mobx-react";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { SyncOutlined } from '@ant-design/icons';

const Leaderboard = observer(class Leaderboard extends React.Component {
	constructor(props) {
		super(props);
		this.showPlayer = this.showPlayer.bind(this);
		this.state = { fullPP: null }
	}

	showPlayer(player) {
		player = ewtStore.players[player.id];
		player.showRound = ewtStore.rounds[ewtStore.currentRoundID];
		ewtStore.showPlayerCard = player;
	}

	render() {
		if (!ewtStore.initialized || !ewtStore.players) return <div>
			<SyncOutlined spin style={{ fontSize: 60 }} />
		</div>

		//var sortedPlayers = this.getSortedPlayers();
		var sortedPlayers = ewtStore.sortedPlayers.filter((player) => { return player.group });

		const cellHeader = { backgroundColor: '#b86969', border: 'none', padding: 0, fontSize: 23, color: 'white', textAlign: 'center' };

		var currentRounds = ewtStore.getCurrentRounds();

		return (
			<div className="flexRow flexGrow" style={{ height: ewtStore.panelHeight, justifyContent: 'center', maxWidth: 400, marginRight: 5, marginTop: 5, border: '1px solid ' + ewtStore.color1, padding: 5, borderRadius: 5 }}>
				{sortedPlayers && <div className="flexGrow flexColumn" style={{ width: '100%' }}>
					<StickyTable border={1} style={{ fontSize: 16 }}>
						<Row style={{ fontSize: 18 }}>
							<Cell style={cellHeader}>Pos</Cell>
							<Cell style={cellHeader}>Player</Cell>
							{ewtStore.currentRound.open && <Cell style={cellHeader}>{ewtStore.rounds[ewtStore.currentRoundID].shortName}</Cell>}
							<Cell style={cellHeader}>Thru</Cell>
							{currentRounds.length > 1 && <Cell style={cellHeader}>Tot</Cell>}
							{!ewtStore.currentRound.open && <Cell></Cell>}
						</Row>
						{sortedPlayers.map((player, index) => {
							return (
								<Row key={index} style={{ fontSize: 18 }}>
									<Cell style={{ border: '1px solid lightgray', textAlign: 'center', fontSize: 18 }}>{player.position}</Cell>
									<Cell style={{ border: '1px solid lightgray', textAlign: 'left', padding: 5, cursor: 'pointer' }} onClick={() => this.showPlayer(player)}>
										{ewtStore.players[player.id] && ewtStore.players[player.id].shortName}
										{/* {ewtStore.players[player.id] && ewtStore.players[player.id].handicaps
											? " (" + ewtStore.players[player.id].handicaps[ewtStore.tournament.id] + ")"
											: ''} */}
									</Cell>
									{ewtStore.currentRound.open && <Cell style={{ border: '1px solid lightgray', textAlign: 'center' }}>
										<nobr style={{ color: player.currentRoundNet < 0 ? 'red' : player.currentRoundNet === 0 ? 'green' : 'black' }}>
											{player.currentRoundNetStr}
										</nobr>
									</Cell>}
									<Cell style={{ border: '1px solid lightgray', textAlign: 'center', fontSize: 16 }}>
										{player.through === 0 ? player.group ? player.group.teeTime : "" : player.through === 18 ? 'F' : player.through}
									</Cell>
									{currentRounds.length > 1 && <Cell style={{ border: '1px solid lightgray', textAlign: 'center' }}>
										<nobr style={{ color: player.total < 0 ? 'red' : player.total === 0 ? 'green' : 'black' }}>{player.totalStr}</nobr>
									</Cell>}
									{!ewtStore.currentRound.open && <Cell>
										{player.pictureURL && <img
											src={player.pictureURL}
											alt={""}
											style={{
												cursor: "pointer",
												width: 100,
												height: 65,
												objectFit: "contain",
											}}
											onClick={(e) => { e.stopPropagation(); this.setState({ fullPP: this.state.fullPP ? null : player.pictureURL ? player.pictureURL : null }) }
											}
										/>}

									</Cell>}
								</Row>
							)
						})}
					</StickyTable>
				</div>}
				{this.state.fullPP && (
					<img
						src={this.state.fullPP}
						alt={""}
						style={{ width: "300px", height: "auto", position: 'absolute', top: 50, left: 20, zIndex: 999 }}
						onClick={() => this.setState({ fullPP: null })}
					></img>
				)}
			</div>
		);
	}
});


export default Leaderboard;