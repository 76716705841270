import React from 'react';
import { observer } from "mobx-react"
import ewtStore from '../EWTStore';
import { StickyTable, Row, Cell } from 'react-sticky-table';

const CourseCard = observer(class CourseCard extends React.Component {

	render() {
		var roundID = this.props.roundID;
		if (roundID === "" && ewtStore.currentRoundID) roundID = ewtStore.currentRoundID;
		var round = ewtStore.rounds[roundID];
		var course = ewtStore.courses[round.courseID];
		if (!round) return <div></div>
		var holes = new Array(9).fill(0);
		var frontPar = 0;
		var frontYardage = 0;
		for (var i=0;i<9;i++) {
			frontPar += course.holes[i+1].tees[round.tees].par;
			frontYardage += course.holes[i+1].tees[round.tees].yardage;
		}
		var backPar = 0;
		var backYardage = 0;
		for (i=9;i<18;i++) {
			backPar += course.holes[i+1].tees[round.tees].par;
			backYardage += course.holes[i+1].tees[round.tees].yardage;
		}

		var newPlayers = Object.keys(ewtStore.players).map((playerID) => ewtStore.players[playerID]);
		newPlayers = newPlayers.sort(function(a,b) {
			var aa = a.last_name + "_" + a.first_name;
			var bb = b.last_name + "_" + b.first_name;
			if (aa > bb)
			  return 1;
			else if (aa < bb)
			  return -1;
			return 0
		})


		const cellStyle = {border:'1px solid lightgray',textAlign:'center',width:20,padding:1}
		return (
		<div style={{marginTop:5,height:ewtStore.showCourseCard?(ewtStore.panelHeight+70):ewtStore.panelHeight}}>
			{!ewtStore.showMap && <>
			<StickyTable border={1} style={{fontSize:20}} stickyHeaderCount={1}>
				<Row>
 					<Cell style={cellStyle}>Hole</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(1)}>1</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(2)}>2</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(3)}>3</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(4)}>4</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(5)}>5</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(6)}>6</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(7)}>7</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(8)}>8</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(9)}>9</Cell>
					<Cell style={cellStyle}>Out</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(10)}>10</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(11)}>11</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(12)}>12</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(13)}>13</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(14)}>14</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(15)}>15</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(16)}>16</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(17)}>17</Cell>
					<Cell style={cellStyle} onClick={(e) => ewtStore.showHoleMap(18)}>18</Cell>
					<Cell style={cellStyle}>In</Cell>
					<Cell style={cellStyle}>Total</Cell>
            	</Row>
				<Row>
					<Cell style={cellStyle}>Par</Cell>
					{holes.map((hole, index) => {
						return (<Cell key={index} style={cellStyle}>{course.holes[index+1].tees[round.tees].par}</Cell>)
					})}
					<Cell style={cellStyle}>{frontPar}</Cell>
					{holes.map((hole, index) => {
						return (<Cell key={index+10} style={cellStyle}>{course.holes[index+10].tees[round.tees].par}</Cell>)
					})}
					<Cell style={cellStyle}>{backPar}</Cell>
					<Cell style={cellStyle}>{frontPar+backPar}</Cell>
				</Row>
				<Row>
					<Cell style={cellStyle}>Yards</Cell>
					{holes.map((hole, index) => {
						return (<Cell key={index} style={cellStyle}>{course.holes[index+1].tees[round.tees].yardage}</Cell>)
					})}
					<Cell style={cellStyle}>{frontYardage}</Cell>
					{holes.map((hole, index) => {
						return (<Cell key={index+10} style={cellStyle}>{course.holes[index+10].tees[round.tees].yardage}</Cell>)
					})}
					<Cell style={cellStyle}>{backYardage}</Cell>
					<Cell style={cellStyle}>{frontYardage+backYardage}</Cell>
				</Row>
				{ewtStore.initialized && newPlayers.map((player,index) => {
					var playerID = player.id;
				return (
					<Row key={index}>
						<Cell style={Object.assign(cellStyle,{cursor:'pointer'})} onClick={()=> ewtStore.showPlayerCard=player}>{player.shortName}</Cell>
						{holes.map((hole, index) => {
  							return (<Cell key={index} style={cellStyle}>{ewtStore.roundScores[roundID] && ewtStore.roundScores[roundID][playerID] && ewtStore.roundScores[roundID][playerID][index+1] && ewtStore.roundScores[roundID][playerID][index+1].score}</Cell>)
						})}
						<Cell style={cellStyle}>{ewtStore.roundScores[roundID] && ewtStore.roundScores[roundID][playerID] && ewtStore.roundScores[roundID][playerID]["F"]}</Cell>
						{holes.map((hole, index) => {
  							return (<Cell key={index+9} style={cellStyle}>{ewtStore.roundScores[roundID] && ewtStore.roundScores[roundID][playerID] && ewtStore.roundScores[roundID][playerID][index+10] && ewtStore.roundScores[roundID][playerID][index+10].score}</Cell>)
						})}
						<Cell style={cellStyle}>{ewtStore.roundScores[roundID] && ewtStore.roundScores[roundID][playerID] && ewtStore.roundScores[roundID][playerID]["B"]}</Cell>
						<Cell style={cellStyle}>{ewtStore.roundScores[roundID] && ewtStore.roundScores[roundID][playerID] && ewtStore.roundScores[roundID][playerID]["T"]}</Cell>
					</Row>
				)
			})}
          </StickyTable></>}
		  {ewtStore.showMap && <img src={ewtStore.courseMapURL} alt={'none available'} 
		  							style={{width:ewtStore.windowWidth}}></img>}
		</div>
	  );
	}
});


export default CourseCard;